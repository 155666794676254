<template>
  <v-container class="classDetailsWrapper">
    <v-card class="d-md-flex align-center cardWrapper" elevation="2">
      <v-img class="cardImg" :src="`${cookingClassDetails.image.url}`"></v-img>
      <div class="cardText">
        <p class="text-h2 text-center font-weight-bold">
          {{ cookingClassDetails.name }}
        </p>
        <v-card-text v-html="descriptionMarkdown"></v-card-text>
        <v-card-text v-if="cookingClassDetails.Menu"
          ><b>Menu:</b> {{ cookingClassDetails.Menu }}</v-card-text
        >
        <v-card-text v-if="cookingClassDetails.Instructor"
          ><b>Instructor: </b>{{ cookingClassDetails.Instructor }}</v-card-text
        >
        <v-card-text v-if="cookingClassDetails.entreeOnePrice"
          ><b>Ticket Price - Entree Option One: </b>${{
            cookingClassDetails.entreeOnePrice
          }}
          ({{ cookingClassDetails.entreeOneName }})</v-card-text
        >
        <v-card-text v-if="cookingClassDetails.entreeTwoPrice"
          ><b>Ticket Price - Entree Option Two: </b>${{
            cookingClassDetails.entreeTwoPrice
          }}
          ({{ cookingClassDetails.entreeTwoName }})</v-card-text
        >

        <v-card-text>
          <b>When:</b>
          {{ humanReadableClassDate }}
        </v-card-text>
        <v-card-text v-if="cookingClassDetails.Location">
          <b>Location:</b> {{ cookingClassDetails.Location }}
        </v-card-text>
      </div>
    </v-card>
    <DisclosuresCancellations />
    <v-dialog v-model="soldOutDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Class Sold Out!
        </v-card-title>
        <br />
        <v-card-text
          >Unforunately there aren't enough tickets left! While this class has
          sold out, please check out our other classes!</v-card-text
        >
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeRefresh">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Tickets
      :ticketProps="ticketProps"
      @nextStep="nextStep"
      @prevStep="prevStep"
      @increasePartySize="increasePartySize"
      @decreasePartySize="decreasePartySize"
      @updateAddOns="updateAddOns"
      @updateEntrees="updateEntrees"
      @updateTotalTicketPrice="updateTotalTicketPrice"
      @getCurrentTicketCount="getCurrentTicketCount"
      @updateTicketCount="updateTicketCount"
      @updateSeatingPreference="updateSeatingPreference"
      ref="ticketsRef"
    />
  </v-container>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import axios from "axios";
import Tickets from "./Tickets";
import DisclosuresCancellations from "./DisclosuresCancellations";
import { createHumanReadableDateString } from "../Util/helper.js";
import { marked } from "marked"; 

export default {
  name: "ClassesDetails",
  data: () => ({
    cookingClassDetails: [],
    soldOutDialog: false,
    currentTicketCount: null,
    ticketProps: {
      step: 1,
      ticketTypes: {
        entreeOne: {
          name: null,
          price: null,
        },
        entreeTwo: {
          name: null,
          price: null,
        },
        addOnOne: {
          name: null,
          price: null,
        },
        addOnTwo: {
          name: null,
          price: null,
        },
      },
      humanReadableClassDate: null,
      descriptionMarkdown: null,
      //TODO - add all the v-bind: functions to a single object for (like ticketProps)
      availableTickets: null,
      maxPartySize: null,
      maxSingleTickets: 4,
      maxPairTickets: 10,
      //max of sigle tickets per event/class is 4
      availableSingleTickets: 4,
      //max number of pair tickets is 10
      availablePairTickets: 10,
      singleTickets: 0,
      pairTickets: 0,
      //ticket or cart objet for the following values
      totalTicketPrice: 0,
      totalTicketCount: 0,
      addOnsTotal: 0,
      entreeOneTicketCount: null,
      entreeTwoTicketCount: null,
      entreePriceTotal: [],
      entreePricePerTicket: 0,
      //ticketId as [key] entreePrice [value] for Map
      entreeTotalObject: new Map(),
      entreeTicketCountObject: new Map(),
      classCategory: "",
      seatingPreference: {},
    },
  }),
  methods: {
    closeRefresh() {
      this.soldOutDialog = false;
      window.location.reload();
    },
    updateTicketCount(numberOfTickets) {
      axios
        .get(
          `https://strapi.nyk.mindex.cloud/cooking-classes/${this.$route.params.id}`
        )
        .then((res) => {
          this.currentTicketCount = res.data.tickets;
          if (this.currentTicketCount >= numberOfTickets) {
            return (this.currentTicketCount =
              this.currentTicketCount - numberOfTickets);
          } else {
            alert("Unforunately there aren't enough tickets left!");
          }
        })
        .then((result) => {
          var data = JSON.stringify({
            tickets: `${this.currentTicketCount}`,
          });
          var config = {
            method: "put",
            url: `https://strapi.nyk.mindex.cloud/cooking-classes/${this.$route.params.id}`,
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_STRAPI_API_KEY}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          axios(config)
            .then(() => {
              this.$router.push("/thankyou");
            })
            .catch((error) => {
              this.$router.push("/classes");
              alert(
                "Your purchase was successful, however there was an issue making the reservation. Please contact us at 585-394-7070 to confirm your order. Thanks!"
              );

              console.log(error);
            });
        });
    },
    nextStep() {
      this.ticketProps.step++;
    },
    prevStep() {
      this.ticketProps.step--;
    },

    updateAddOns(newTotal) {
      if (newTotal.length === 0) {
        this.ticketProps.addOnsTotal = 0;
      } else {
        let newAddOnTotal = newTotal.map(Number).reduce((a, b) => a + b);
        this.ticketProps.addOnsTotal = newAddOnTotal;
      }
    },

    updateEntrees(orderIds) {
      let entreePrice;

      const multiplier = orderIds.ticketId.startsWith("pair") ? 2 : 1;
      //get price for UI, get entreeOne/Two count for lambda/payment logic
      if (orderIds.entreeId == 1) {
        entreePrice = this.ticketProps.ticketTypes.entreeOne.price * multiplier;
        //give single or pair entreeOneTicketCount
        this.ticketProps.entreeTicketCountObject[orderIds.ticketId] = {
          entreeOne: multiplier,
        };
      } else if (orderIds.entreeId == 2) {
        entreePrice = this.ticketProps.ticketTypes.entreeTwo.price * multiplier;
        //give single or pair entreeTwoTicketCount
        this.ticketProps.entreeTicketCountObject[orderIds.ticketId] = {
          entreeTwo: multiplier,
        };
      }

      let newEntreeValue = parseFloat(entreePrice);

      //Map logic
      this.ticketProps.entreeTotalObject[orderIds.ticketId] = entreePrice;

      //need to get total of all ticket entrees BUT individual entrees when toggled should only reflect the new entree price
      this.ticketProps.entreePricePerTicket = newEntreeValue;
      this.ticketProps.entreePriceTotal = this.ticketProps.entreeTotalObject
        ? Object.values(this.ticketProps.entreeTotalObject).reduce(
            (a, b) => a + b
          )
        : 0;
    },

    calculateEntreeTicketCount(key, obj) {
      var entreeCount = 0;
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop) && key === prop) {
          entreeCount += obj[prop];
        } else if (
          Object.prototype.toString.call(obj[prop]) === "[object Object]"
        ) {
          entreeCount += this.calculateEntreeTicketCount(key, obj[prop]);
        }
      }
      return entreeCount;
    },
    updateTotalTicketPrice() {
      if (this.ticketProps.addOnsTotal !== 0) {
        this.ticketProps.totalTicketPrice =
          this.ticketProps.addOnsTotal + this.ticketProps.entreePriceTotal;
      } else {
        this.ticketProps.totalTicketPrice = this.ticketProps.entreePriceTotal;
      }
      const entreeOneCount = this.calculateEntreeTicketCount(
        "entreeOne",
        this.ticketProps.entreeTicketCountObject
      );
      const entreeTwoCount = this.calculateEntreeTicketCount(
        "entreeTwo",
        this.ticketProps.entreeTicketCountObject
      );
      this.ticketProps.entreeOneTicketCount = entreeOneCount;
      this.ticketProps.entreeTwoTicketCount = entreeTwoCount;
      this.ticketProps.totalTicketCount = entreeOneCount + entreeTwoCount;
    },

    increasePartySize(partySize) {
      //check to see if tickets are still available
      if (this.ticketProps.availableTickets <= this.ticketProps.maxPartySize) {
        this.ticketProps.availableTickets--;
        this.calculatePairSingleTickets(partySize);
        //reset entree ticket counts and price objects
        this.ticketProps.entreeTicketCountObject = new Map();
        this.ticketProps.entreeTotalObject = new Map();
      }
    },
    decreasePartySize(partySize) {
      if (this.ticketProps.availableTickets <= this.ticketProps.maxPartySize) {
        this.ticketProps.availableTickets++;
        this.calculatePairSingleTickets(partySize);
        //reset entree ticket counts and price objects
        this.ticketProps.entreeTicketCountObject = new Map();
        this.ticketProps.entreeTotalObject = new Map();
      }
    },

    calculatePairSingleTickets(partySize) {
      //determine number of pair and single tickets

      //if even party size
      if (partySize % 2 === 0) {
        //calculate number of pair tickets (max 10) and single tickets (max 4)
        if (partySize <= 20) {
          this.ticketProps.singleTickets = 0;
          this.ticketProps.pairTickets = partySize / 2;
        } else {
          this.ticketProps.singleTickets = partySize - 20;
          this.ticketProps.pairTickets = this.ticketProps.maxPairTickets;
        }

        //if odd party size
      } else {
        //calculate number of pair tickets (max 10) and single tickets (max 4)
        if (partySize <= 20) {
          this.ticketProps.pairTickets = Math.floor(partySize / 2);
          this.ticketProps.singleTickets = 1;
        } else {
          this.ticketProps.pairTickets = this.ticketProps.maxPairTickets;
          this.ticketProps.singleTickets = partySize - 20;
        }
      }
    },
    getCurrentTicketCount(args) {
      axios
        .get(
          `https://strapi.nyk.mindex.cloud/cooking-classes/${this.$route.params.id}`
        )
        .then((res) => {
          if (res.data.tickets >= args.partySize) {
            this.$refs.ticketsRef.validateStep(args.step);
          } else {
            this.ticketProps.availableTickets = res.data.tickets;
            this.soldOutDialog = true;
          }
        });
    },
    updateSeatingPreference(seatingPreference) {
      this.ticketProps.seatingPreference[seatingPreference.seatName] =
        seatingPreference.seatPreference;
    },
  },
  async mounted() {
    try {
      const formatPrice = (num) => {
        return Number(num)
          .toFixed(2)
          .toString();
      };
      const response = await axios.get(
        `https://strapi.nyk.mindex.cloud/cooking-classes/${this.$route.params.id}`
      );
      this.cookingClassDetails = response.data;
      this.ticketProps.classCategory = response.data.category.name;
      this.ticketProps.availableTickets = response.data.tickets;
      this.ticketProps.maxPartySize = response.data.tickets;
      this.ticketProps.ticketTypes.entreeOne.name = response.data.entreeOneName;
      this.ticketProps.ticketTypes.entreeOne.price = formatPrice(
        response.data.entreeOnePrice
      );
      this.ticketProps.ticketTypes.entreeTwo.name = response.data.entreeTwoName;
      this.ticketProps.ticketTypes.entreeTwo.price = formatPrice(
        response.data.entreeTwoPrice
      );
      this.ticketProps.ticketTypes.addOnOne.name = response.data.addOnOneName;
      this.ticketProps.ticketTypes.addOnOne.price = formatPrice(
        response.data.addOneOnePrice
      );
      this.ticketProps.ticketTypes.addOnTwo.name = response.data.addOnTwoName;
      this.ticketProps.ticketTypes.addOnTwo.price = formatPrice(
        response.data.addOneTwoPrice
      );
      this.humanReadableClassDate = createHumanReadableDateString(
        this.cookingClassDetails.startTime,
        this.cookingClassDetails.endTime
      );
      this.descriptionMarkdown = marked(response.data.description);
    } catch (error) {
      this.error = error;
    }
  },
  components: {
    Tickets,
    DisclosuresCancellations,
  },
};
</script>

<style scoped>
.cookingClassHeader {
  color: black;
  text-align: center;
}
.cardWrapper {
  display: flex;
  margin: 40px 0 40px 0;
}
.cardImg {
  height: 100%;
  max-height: 400px;
  max-width: 400px;
  width: 40%;
}
.cardText {
  width: 50%;
}

.classDetailsWrapper {
  max-width: 1285px;
}

@media only screen and (max-width: 600px) {
  .cardImg {
    width: 100%;
    max-height: 500px;
    max-width: none;
  }
  .cardText {
    width: 100%;
  }
  .cardWrapper {
    display: flex;
    flex-direction: column;
  }
}
</style>
