<template>
  <v-container class="classDetailsWrapper">
    <v-card class="d-md-flex align-center cardWrapper" elevation="2">
      <v-img class="cardImg" :src="`${eventsDetails.image.url}`"></v-img>
      <div class="cardText">
        <p class="text-h2 text-center font-weight-bold">
          {{ eventsDetails.name }}
        </p>
        <v-card-text>{{ eventsDetails.description }} </v-card-text>
        <v-card-text v-if="eventsDetails.Menu"><b>Menu:</b> {{eventsDetails.Menu}}</v-card-text>
        <v-card-text>
          <b>When:</b>
          {{
            format(
              new Date(eventsDetails.startTime),
              "iii, LLL dd yyyy h:mm aaa"
            )
          }}
          -
          {{
            format(new Date(eventsDetails.endTime), "iii, LLL dd yyyy h:mm aaa")
          }}
        </v-card-text>
        <v-card-text v-if="eventsDetails.Location">
          <b>Location: </b> {{eventsDetails.Location}}
        </v-card-text>
      </div>
    </v-card>
    <!-- <Tickets
      :ticketProps="ticketProps"
      @nextStep="nextStep"
      @prevStep="prevStep"
      @increasePartySize="increasePartySize"
      @decreasePartySize="decreasePartySize"
      @updateAddOns="updateAddOns"
      @updateEntrees="updateEntrees"
      @updateTotalTicketPrice="updateTotalTicketPrice"
      @getCurrentTicketCount="getCurrentTicketCount"
      ref="ticketsRef"
    /> -->
  </v-container>
</template>

<script>
import axios from "axios";
import { format } from "date-fns";
// import Tickets from "./Tickets";

export default {
  name: "ClassesDetails",
  data: () => ({
    format,
    eventsDetails: [],
    ticketProps: {
      step: 1,
      ticketTypes: {
        entreeOne: {
          name: null,
          price: null,
        },
        entreeTwo: {
          name: null,
          price: null,
        },
        addOnOne: {
          name: null,
          price: null,
        },
        addOnTwo: {
          name: null,
          price: null,
        },
      },
      availableTickets: null,
      maxPartySize: null,
      maxSingleTickets: 4,
      maxPairTickets: 10,
      //max of sigle tickets per event/class is 4
      availableSingleTickets: 4,
      //max number of pair tickets is 10
      availablePairTickets: 10,
      singleTickets: 0,
      pairTickets: 0,
      totalTicketPrice: 0,
      addOnsTotal: 0,
      entreePriceTotal: 0,
      currentTicketCount: null,
    },
  }),
  methods: {
    nextStep() {
      this.ticketProps.step++;
    },
    prevStep() {
      this.ticketProps.step--;
    },

    updateAddOns(newTotal) {
      let newAddOnTotal = newTotal.map(Number).reduce((a, b) => a + b);
      this.ticketProps.addOnsTotal = newAddOnTotal;
    },

    updateEntrees(entree) {
      let newEntreeValue = parseFloat(entree);
      this.ticketProps.entreePriceTotal =
        this.ticketProps.entreePriceTotal + newEntreeValue;
    },

    updateTotalTicketPrice() {
      if (this.ticketProps.addOnsTotal !== 0) {
        this.ticketProps.totalTicketPrice =
          this.ticketProps.addOnsTotal + this.ticketProps.entreePriceTotal;
      } else {
        this.ticketProps.totalTicketPrice = this.ticketProps.entreePriceTotal;
      }

    },

    increasePartySize(partySize) {
      //check to see if tickets are still available
      if (this.ticketProps.availableTickets <= this.ticketProps.maxPartySize) {
        this.ticketProps.availableTickets--;
        this.calculatePairSingleTickets(partySize);
      }
    },
    decreasePartySize(partySize) {
      if (this.ticketProps.availableTickets <= this.ticketProps.maxPartySize) {
        this.ticketProps.availableTickets++;
        this.calculatePairSingleTickets(partySize);
      }
    },

    calculatePairSingleTickets(partySize) {
      //determine number of pair and single tickets

      //if even party size
      if (partySize % 2 === 0) {
        //calculate number of pair tickets (max 10) and single tickets (max 4)
        if (partySize <= 20) {
          this.ticketProps.singleTickets = 0;
          this.ticketProps.pairTickets = partySize / 2;
        } else {
          this.ticketProps.singleTickets = partySize - 20;
          this.ticketProps.pairTickets = this.ticketProps.maxPairTickets;
        }

        //if odd party size
      } else {
        //calculate number of pair tickets (max 10) and single tickets (max 4)
        if (partySize <= 20) {
          this.ticketProps.pairTickets = Math.floor(partySize / 2);
          this.ticketProps.singleTickets = 1;

        } else {
          this.ticketProps.pairTickets = this.ticketProps.maxPairTickets;
          this.ticketProps.singleTickets = partySize - 20;
        }
      }
    },
    getCurrentTicketCount(args){
       axios.get(
          `https://strapi.nyk.mindex.cloud/cooking-classes/${this.$route.params.id}`
        )
        .then((res) => {
           this.ticketProps.availableTickets = res.data.tickets;
          if(this.ticketProps.availableTickets >= args.partySize){
            this.$refs.ticketsRef.validateStep(args.step)
          } else {
            alert("Unforunately there aren't enough tickets left! Please select a valid party size")
          }
          console.log("current tickets", this.ticketProps.availableTickets)
        });
    }
  },
  async mounted() {
    try {
      const response = await axios.get(
        `https://strapi.nyk.mindex.cloud/events/${this.$route.params.id}`
      );
      this.eventsDetails = response.data;
      this.ticketProps.availableTickets = response.data.tickets;
      this.ticketProps.maxPartySize = response.data.tickets;
      this.ticketProps.ticketTypes.entreeOne.name = response.data.entreeOneName;
      this.ticketProps.ticketTypes.entreeOne.price =
        response.data.entreeOnePrice;
      this.ticketProps.ticketTypes.entreeTwo.name = response.data.entreeTwoName;
      this.ticketProps.ticketTypes.entreeTwo.price =
        response.data.entreeTwoPrice;
      this.ticketProps.ticketTypes.addOnOne.name = response.data.addOnOneName;
      this.ticketProps.ticketTypes.addOnOne.price =
        response.data.addOneOnePrice;
      this.ticketProps.ticketTypes.addOnTwo.name = response.data.addOnTwoName;
      this.ticketProps.ticketTypes.addOnTwo.price =
        response.data.addOneTwoPrice;
    } catch (error) {
      this.error = error;
    }
  },
  components: {
    // Tickets,
  },
};
</script>

<style scoped>
.cookingClassHeader {
  color: black;
  text-align: center;
}
.cardWrapper {
  display: flex;
  margin: 40px 0 40px 0;
}
.cardImg {
  height: 100%;
  max-height: 400px;
  max-width: 400px;
  width: 40%;
}
.cardText {
  width: 50%;
}

.classDetailsWrapper {
  max-width: 1285px;
}

@media only screen and (max-width: 600px) {
  .cardImg {
    width: 100%;
    max-height: 500px;
    max-width: none;
  }
  .cardText {
    width: 100%;
  }
  .cardWrapper {
  display: flex;
  flex-direction: column;
}
}
</style>
