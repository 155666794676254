<template>
  <v-container v-if="ticketProps" class="vld-parent">
    <loading
      :active.sync="paymentIsLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    ></loading>
    <h1 class="ticketStepHeader">Purchase Your Ticket!</h1>
    <v-stepper v-model="ticketProps.step">
      <v-stepper-header>
        <v-stepper-step :complete="ticketProps.step > 1" step="1">
          Party Details
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2">
          Payment & Confirmation
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- --------------Step 1-------------------- -->
        <v-stepper-content step="1">
          <v-form v-model="stepOneValid" ref="stepOneForm" lazy-validation>
            <v-card class="mb-12">
              <div class="ticketTextWrapper">
                <p>
                  How many people are in your party?*
                </p>
              </div>

              <div class="partySizeWrapper">
                <v-text-field
                  v-model="partySize"
                  :rules="partySizeRules"
                  required
                  placeholder="0"
                  single-line
                  solo
                  disabled
                  class="partySizeTextField"
                ></v-text-field>
                <v-btn
                  v-if="ticketProps.availableTickets >= 1"
                  @click="increasePartySize"
                  >+
                </v-btn>
                <v-btn v-if="this.partySize > 0" @click="decreasePartySize"
                  >-</v-btn
                >
              </div>

              <p>Remaining tickets: {{ ticketProps.availableTickets }}</p>

              <p>Ticket Price: ${{ ticketProps.ticketPrice }}</p>
            </v-card>

            <v-btn
              color="primary"
              @click="getCurrentTicketCount(ticketProps.step)"
            >
              Continue
            </v-btn>
          </v-form>
        </v-stepper-content>
        <!-- -------------------Step 2 ---------------------------->
        <v-stepper-content step="2">
          <v-form
            v-model="paymentInfoValid"
            ref="paymentFormValidation"
            lazy-validation
            @input="validatePaymentForm"
          >
            <p class="checkoutHeader">
              Please provide your email and phone for the reservation
              <span style="color: red;">(required)</span>:
            </p>
            <v-text-field
              class="ticketNameEmailField"
              v-model="buyerName"
              solo
              label="Full Name (First & Last)"
              required
              :rules="fullNameRules"
            >
            </v-text-field>
            <v-text-field
              class="ticketNameEmailField"
              v-model="buyerEmail"
              solo
              label="Email"
              required
              :rules="emailRules"
            >
            </v-text-field>
            <v-text-field
              class="ticketNameEmailField"
              v-model="buyerPhone"
              solo
              label="Phone"
              required
              :rules="phoneRules"
            >
            </v-text-field>
            <p class="checkoutHeader">
              Please review your ticket details and provide a payment method:
            </p>
            <div class="ledgerWrapper">
              <p>
                Total Number of Tickets:
                {{ ticketProps.totalTicketCount }}
              </p>
              <p>Ticket Price: ${{ ticketProps.ticketPrice }}</p>
            </div>
            <div class="divider"></div>

            <div class="totalCheckoutPrice">
              <p class="totalPriceText" v-if="ticketProps.totalTicketPrice > 0">
                Total Price:
              </p>
              <p>${{ ticketProps.totalTicketPrice }}</p>
            </div>

            <p class="checkoutHeader">Card Details</p>
            <div class="stripeWrapper">
              <form id="payment-form" @submit.prevent="handlePaymentForm">
                <div class="stripeCard" ref="card"></div>
                <div style="color: red;" id="card-errors"></div>
                <div style="color: red;" id="payment-errors"></div>
                <button class="payButton" type="submit" :disabled="lockSubmit">
                  Pay
                </button>
              </form>
            </div>
            <div>
              <v-btn @click="prevStep" text>
                Back
              </v-btn>
            </div>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  name: "GeneralClassTickets",
  props: ["ticketProps"],
  data: () => ({
    loadStripe,
    stripe: null,
    //DEV client side key
    // clientSideStripeAPIkey:
    //   "pk_test_51Jh8LAKu67xVf9q1khz6nK7audVYxEy2vnlz9hBMjGz5kWLMnIw78ROep8KAYM99wzBdRPgh26CUTY7LEM9iyu3V00SkPBmWjm",

    //PROD client side key
    clientSideStripeAPIkey: "pk_live_ABNYM6YA6G142Qi8ARq4EKgS00Rtx3cIeG",
    //PROD API Gateway endpoint
    lambdaStripe:
      "https://khadu3qoy3.execute-api.us-east-1.amazonaws.com/Stage/stripe",
    //DEV API Gateway endpoint StripeHandler-Dev
    // lambdaStripe:
    //   "https://oe5d0zq679.execute-api.us-east-1.amazonaws.com/Stage/strapi",

    classId: "",
    classUrl: "",
    lockSubmit: true,
    card: null,
    buyerName: "",
    buyerEmail: "",
    buyerPhone: "",
    paymentInfoValid: false,
    paymentIsLoading: false,
    fullPage: true,
    error: null,
    stepOneValid: false,
    stepTwoValid: false,
    partySizeRules: [
      (v) => v > 0 || "Please enter how many people are in your party",
    ],
    firstNameRules: [(v) => !!v || "First name is required"],
    lastNameRules: [(v) => !!v || "Last name is required"],
    fullNameRules: [(v) => !!v || "Name is required"],
    phoneRules: [(v) => (v || "").length === 10 || "Phone must be valid"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    partySize: null,
    maxPartySize: 24,
    isPurchaseValid: true,
    currentTicketCount: null,
  }),
  components: {
    Loading,
  },
  computed: {
    ticketFormValid() {
      return this.customers;
    },
  },
  methods: {
    validatePaymentForm() {
      if (this.$refs.paymentFormValidation.validate() === true) {
        this.paymentInfoValid = true;
      }
    },
    getCurrentTicketCount(step) {
      this.$emit("getCurrentTicketCount", {
        partySize: this.partySize,
        step: step,
      });
    },

    validateStep(step) {
      if (step === 1 && this.$refs.stepOneForm.validate() === true) {
        this.$emit("updateTotalTicketPrice", this.partySize);
        this.$emit("nextStep");
      }
      // this.$emit("updateEntrees", this.entreeSelected);
    },

    handlePaymentForm(event) {
      event.preventDefault();
      //confirm validation before proceeding
      if (this.paymentInfoValid === true) {
        this.paymentIsLoading = true;
        this.lockSubmit = true;
        const paymentError = document.getElementById("payment-errors");

        //check ticket count before proceeding with payment
        this.getCurrentTicketCount(this.ticketProps.step);
        //confirm name, email, and phone have been entered for payment, if validated, proceedd with payment
        // if (this.ticketProps.availableTickets >= this.partySize) {
        this.stripe.createToken(this.card).then(async (result) => {
          //dummy credit card info
          //cardNumber: 4242 4242 4242 4242
          //cardExpir: 09/22
          //cardCvC: 314
          //zip: 12345
          if (result.error) {
            // alert(result.error.message);
            paymentError.textContent =
              "There was an error with checkout, please ensure your card details are correct and try again.";
            this.paymentIsLoading = false;
            this.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
            return;
          } else {
            //result.token.id is the transactionToken that grabs the info entered into the stripe card element
            const intent = await fetch(this.lambdaStripe, {
              method: "POST",
              body: JSON.stringify({
                transactionToken: result.token.id,
                classId: this.$route.params.id,
                classUrl: "https://strapi.nyk.mindex.cloud/general-classes/",
                numberOfTickets: this.ticketProps.totalTicketCount,
                addOns: 0,
                classCategory: this.ticketProps.classCategory,
                entreeOneTickets: 0,
                entreeTwoTickets: 0,
                email: this.buyerEmail,
                phone: this.buyerPhone,
                buyerName: this.buyerName,
              }),
            });
            const { paymentIntent } = await intent.json();
            await this.stripe
              .confirmCardPayment(paymentIntent.client_secret, {
                payment_method: {
                  card: this.card,
                  billing_details: {
                    //this would be the inputs from the Ticket form at step 4 name, email,phone
                    email: this.buyerEmail,
                    phone: this.buyerPhone,
                    name: this.buyerName,
                  },
                },
              })
              .then(async (result) => {
                if (result.error) {
                  this.paymentIsLoading = false;
                  paymentError.textContent = result.error.message;
                  //if fails
                  //GET to Insert-Email-Dev lambda
                  axios
                    .get(
                      `https://khadu3qoy3.execute-api.us-east-1.amazonaws.com/Stage/insertemail?succeded=false&orderid=${paymentIntent.id}`
                      // `https://oe5d0zq679.execute-api.us-east-1.amazonaws.com/Stage/insertemail?succeded=false&orderid=${paymentIntent.id}`
                    )
                    .then(() => {
                      this.paymentIsLoading = false;
                    })
                    .catch((err) => console.log(err.type, err.code));
                } else {
                  axios
                    .get(
                      `https://khadu3qoy3.execute-api.us-east-1.amazonaws.com/Stage/insertemail?succeded=true&orderid=${paymentIntent.id}`
                      // `https://oe5d0zq679.execute-api.us-east-1.amazonaws.com/Stage/insertemail?succeded=true&orderid=${paymentIntent.id}`
                    )
                    .then(() => {
                      this.$emit(
                        "updateTicketCount",
                        this.ticketProps.totalTicketCount
                      );
                      this.paymentIsLoading = false;
                    })
                    .catch((err) => console.log(err));
                }
              });
          }
        });
        // } else {
        //   this.paymentIsLoading = false;
        //   console.log("There was an error with checkout");
        // }
      }
    },

    prevStep() {
      this.$emit("prevStep");
    },
    increasePartySize() {
      if (this.ticketProps.availableTickets !== 0) {
        this.partySize++;
      }
      this.$emit("increasePartySize", this.partySize);
    },
    decreasePartySize() {
      this.partySize--;
      this.$emit("decreasePartySize", this.partySize);
    },
    errorListener() {
      this.card.on("change", (event) => {
        const displayError = document.getElementById("card-errors");
        const paymentError = document.getElementById("payment-errors");
        if (event.complete) {
          this.lockSubmit = false;
          displayError.textContent = "";
        } else if (event.error) {
          this.lockSubmit = true;
          displayError.textContent = event.error.message;
          // alert(event.error);
        } else {
          displayError.textContent = "";
          paymentError.textContent = "";
        }
      });
    },
  },
  async mounted() {
    this.paymentInfoValid = false;

    try {
      this.stripe = await loadStripe(this.clientSideStripeAPIkey);
      this.card = this.stripe.elements().create("card");
      this.card.mount(this.$refs.card);
      this.errorListener();
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
.ticketForm {
  width: 70%;
}
.ticketFormWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.ticketWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 800px;
  margin: auto;
}

.stepOneCard {
  width: 100%;
  padding: 20px;
  margin: 15px;
}
.partySizeTextField {
  width: 150px;
  margin: 15px 0 15px 0;
}

.partySizeWrapper {
  /* display: flex;
  justify-content: flex-start; */
}

.ticketTextWrapper {
  /* display: flex; */
}

.pairSingleTicketWrapper {
  margin: 10px 0 10px 0;
  padding: 10px;
}
.entreeWrapper {
  display: flex;
}
.entreeDetails {
  display: flex;
  margin: 10px;
}
.entreeNamePrice {
  margin: 10px;
}
.ticketTypeHeader {
  font-weight: bold;
}
.ticketStepHeader {
  text-align: center;
  margin: 10px 0px 40px 0px;
}
.ticketNameEmailWrapper {
  display: flex;
  flex-direction: column;
}
.ticketNameEmailWrapperPair {
  display: flex;
  flex-direction: column;
}
.ticketNameEmailField {
  width: 50%;
  min-height: 60px;
}
.ticketNameEmailFieldPair {
  width: 50%;
  min-height: 60px;
}
.totalCheckoutPrice {
  display: flex;
  justify-content: space-between;
  background-color: #b6f9b6;
  padding: 10px 6px 10px 6px;
  width: 50%;
  border-radius: 16px;
  margin: 0 0 20px 0;
  height: 45px;
}
.totalPriceText {
  font-weight: bold;
}
.stripeWrapper {
  width: 50%;
  border-radius: 16px;
  margin: 10px 0 10px 0;
  min-height: 100px;
}
.stripeCard {
  margin: 20px 0 20px 0;
}
.payButton {
  background-color: lightgreen;
  width: 200px;
  height: 30px;
  border-radius: 16px;
}
.checkoutHeader {
  font-weight: bold;
}
.ledgerWrapper {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.divider {
  width: 50%;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}
@media only screen and (max-width: 600px) {
  .ticketNameEmailField {
    width: 95%;
  }
  .entreeDetails {
    margin: 10px 0 10px 0;
  }
  .totalCheckoutPrice {
    display: flex;
    width: 100%;
    margin: 0 0 20px 0;
  }
  .stripeWrapper {
    width: 90%;
  }
  .ledgerWrapper {
    width: 100%;
  }
  .divider {
    width: 100%;
  }
}
</style>
