<template>
  <v-container fluid pa-0>
    <Calendar v-if="mainpage == null" :calendartype="3"> </Calendar>
    <Calendar v-if="mainpage" :calendartype="3"> </Calendar>
    <div class="classesRoot">
      <div class="classCardWrapper">
        <v-card
          class="my-12 classCard"
          max-width="374"
          v-for="allClass in allClasses"
          :key="allClass.id"
        >
          <v-img height="350" :src="`${allClass.image.url}`"></v-img>

          <v-card-title>{{ allClass.name }}</v-card-title>
          <v-card-text>
            <v-row align="center" class="mx-0">
              <v-card-text>
                {{
                  format(
                    new Date(allClass.startTime),
                    "iii, LLL dd yyyy h:mm aaa"
                  )
                }}
                -
                {{
                  format(
                    new Date(allClass.endTime),
                    "iii, LLL dd yyyy h:mm aaa"
                  )
                }}</v-card-text
              >
            </v-row>

            <div>{{ allClass.description }}</div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>
          <div v-if="allClass.tickets > 0">
            <v-card-title>Sign Up Now!</v-card-title>
            <v-card-text v-if="allClass.tickets > 0"
              >{{ allClass.tickets }} tickets left</v-card-text
            >
            <v-card-text v-if="allClass.category.name === 'cooking-class'"
              >Ticket Price - Entree Option One: ${{
                allClass.entreeOnePrice
              }}</v-card-text
            >
            <v-card-text
              v-if="
                allClass.category.name === 'cooking-class' &&
                  allClass.entreeTwoPrice
              "
              >Ticket Price - Entree Option Two: ${{
                allClass.entreeTwoPrice
              }}</v-card-text
            >
            <v-card-text v-if="allClass.category.name !== 'cooking-class'"
              >Ticket Price: ${{ allClass.ticketPrice }}</v-card-text
            >
            <v-card-actions class="reserveButtonWrapper">
              <router-link
                v-if="allClass.category.name === 'cooking-class'"
                class="classDetailsLink"
                :to="`/classesdetails/${allClass.id}`"
              >
                <v-btn
                  class="reserveButton"
                  color="white lighten-2"
                  text
                  outlined
                >
                  Reserve Your Spot!
                </v-btn>
              </router-link>
              <router-link
                v-else
                class="classDetailsLink"
                :to="`/generalclassesdetails/${allClass.id}`"
              >
                <v-btn
                  class="reserveButton"
                  color="white lighten-2"
                  text
                  outlined
                >
                  Reserve Your Spot!
                </v-btn>
              </router-link>
            </v-card-actions>
          </div>
          <div v-else>
           <v-card-text class="soldOutText">Class Sold Out!</v-card-text>
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import { format } from "date-fns";
import Calendar from "./Calendar";

export default {
  name: "Classes",
  props: ["allClasses", "mainpage"],
  data: () => ({
    format,
    events: [],
  }),
  components: {
    Calendar,
  },
};
</script>

<style scoped>
.soldOutText {
  font-size: 1.25rem;
}
.classesRoot {
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.classCardWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 110%;
}
@media only screen and (max-width: 960px) {
  .classCardWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.classCard {
  margin: 20px;
}

.classDetailsLink {
  text-decoration: none;
}

.reserveButton {
  background-color: #4caf50;
  text-transform: none;
  justify-content: center;
  margin-bottom: 10px;
}

.reserveButtonWrapper {
  justify-content: center;
  margin-bottom: 10px;
}
</style>
