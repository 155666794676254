<template>
  <v-container class="classDetailsWrapper">
    <v-card class="d-md-flex align-center cardWrapper" elevation="2">
      <v-img class="cardImg" :src="`${generalClassDetails.image.url}`"></v-img>
      <div class="cardText">
        <p class="text-h2 text-center font-weight-bold">
          {{ generalClassDetails.name }}
        </p>
        <v-card-text v-html="descriptionMarkdown"></v-card-text>
        <v-card-text v-if="generalClassDetails.Menu"
          ><b>Menu: </b>{{ generalClassDetails.Menu }}</v-card-text
        >
        <v-card-text v-if="generalClassDetails.Instructor"
          ><b>Instructor: </b>{{ generalClassDetails.Instructor }}</v-card-text
        >
        <v-card-text v-if="generalClassDetails.ticketPrice"
          ><b>Ticket Price: </b>${{
            generalClassDetails.ticketPrice
          }}</v-card-text
        >
        <v-card-text>
          <b>When:</b>
          {{ humanReadableClassDate }}
        </v-card-text>
        <v-card-text v-if="generalClassDetails.Location">
          <b>Location:</b> {{ generalClassDetails.Location }}
        </v-card-text>
      </div>
    </v-card>

    <DisclosuresCancellations />

    <v-dialog v-model="soldOutDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Class Sold Out!
        </v-card-title>
        <br />
        <v-card-text
          >Unforunately there aren't enough tickets left! While this class has
          sold out, please check out our other classes!</v-card-text
        >
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeRefresh">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <GeneralClassTickets
      :ticketProps="ticketProps"
      @nextStep="nextStep"
      @prevStep="prevStep"
      @increasePartySize="increasePartySize"
      @decreasePartySize="decreasePartySize"
      @updateTotalTicketPrice="updateTotalTicketPrice"
      @getCurrentTicketCount="getCurrentTicketCount"
      @updateTicketCount="updateTicketCount"
      ref="ticketsRef"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import GeneralClassTickets from "./GeneralClassTickets";
import DisclosuresCancellations from "./DisclosuresCancellations";
import { createHumanReadableDateString } from "../Util/helper.js";
import { marked } from "marked";

export default {
  name: "GeneralClassesDetails",
  data: () => ({
    generalClassDetails: [],
    soldOutDialog: false,
    currentTicketCount: null,
    ticketProps: {
      step: 1,
      availableTickets: null,
      maxPartySize: null,
      totalTicketCount: null,
      totalTicketPrice: 0,
      ticketPrice: null,
    },
    humanReadableClassDate: null,
    descriptionMarkdown: null,
  }),
  methods: {
    closeRefresh() {
      this.soldOutDialog = false;
      window.location.reload();
    },
    updateTicketCount(numberOfTickets) {
      axios
        .get(
          `https://strapi.nyk.mindex.cloud/general-classes/${this.$route.params.id}`
        )
        .then((res) => {
          this.currentTicketCount = res.data.tickets;
          if (this.currentTicketCount >= numberOfTickets) {
            return (this.currentTicketCount =
              this.currentTicketCount - numberOfTickets);
          } else {
            alert("Unforunately there aren't enough tickets left!");
          }
        })
        .then(() => {
          var data = JSON.stringify({
            tickets: `${this.currentTicketCount}`,
          });
          var config = {
            method: "put",
            url: `https://strapi.nyk.mindex.cloud/general-classes/${this.$route.params.id}`,
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_STRAPI_API_KEY}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          axios(config)
            .then(() => {
              this.$router.push("/thankyou");
            })
            .catch((error) => {
              this.$router.push("/classes");
              alert(
                "Your purchase was successful, however there was an issue making the reservation. Please contact us at 585-394-7070 to confirm your order. Thanks!"
              );

              console.log(error);
            });
        });
    },
    nextStep() {
      this.ticketProps.step++;
    },
    prevStep() {
      this.ticketProps.step--;
    },
    updateTotalTicketPrice(partySize) {
      this.ticketProps.totalTicketPrice =
        partySize * this.ticketProps.ticketPrice;
    },

    increasePartySize(partySize) {
      //check to see if tickets are still available
      if (this.ticketProps.availableTickets <= this.ticketProps.maxPartySize) {
        this.ticketProps.availableTickets--;
        this.ticketProps.totalTicketCount = partySize;
      }
    },
    decreasePartySize(partySize) {
      if (this.ticketProps.availableTickets <= this.ticketProps.maxPartySize) {
        this.ticketProps.availableTickets++;
        this.ticketProps.totalTicketCount = partySize;
      }
    },
    getCurrentTicketCount(args) {
      axios
        .get(
          `https://strapi.nyk.mindex.cloud/general-classes/${this.$route.params.id}`
        )
        .then((res) => {
          if (res.data.tickets >= args.partySize) {
            this.$refs.ticketsRef.validateStep(args.step);
          } else {
            this.ticketProps.availableTickets = res.data.tickets;
            this.soldOutDialog = true;
          }
        });
    },
  },
  async mounted() {
    try {
      const response = await axios.get(
        `https://strapi.nyk.mindex.cloud/general-classes/${this.$route.params.id}`
      );
      this.generalClassDetails = response.data;
      this.ticketProps.availableTickets = response.data.tickets;
      this.ticketProps.maxPartySize = response.data.tickets;
      this.ticketProps.ticketPrice = response.data.ticketPrice;
      this.ticketProps.classCategory = response.data.category.name;
      this.humanReadableClassDate = createHumanReadableDateString(
        this.generalClassDetails.startTime,
        this.generalClassDetails.endTime
      );
      this.descriptionMarkdown = marked(response.data.description);
    } catch (error) {
      this.error = error;
    }
  },
  components: {
    GeneralClassTickets,
    DisclosuresCancellations,
  },
};
</script>

<style scoped>
.cookingClassHeader {
  color: black;
  text-align: center;
}
.cardWrapper {
  display: flex;
  margin: 40px 0 40px 0;
}
.cardImg {
  height: 100%;
  max-height: 400px;
  max-width: 400px;
  width: 40%;
}
.cardText {
  width: 50%;
}

.classDetailsWrapper {
  max-width: 1285px;
}

@media only screen and (max-width: 600px) {
  .cardImg {
    width: 100%;
    max-height: 500px;
    max-width: none;
  }
  .cardText {
    width: 100%;
  }
  .cardWrapper {
    display: flex;
    flex-direction: column;
  }
}
</style>
