<template>
  <v-container class="classPageRoot">
    <p class="text-h2 text-center font-weight-bold">All Classes</p>
    <Classes v-bind:allClasses="allClasses" />
  </v-container>
</template>

<script>
import Classes from './Classes';
import axios from 'axios';
import formatISO from 'date-fns/formatISO';

export default {
  name: 'ClassesPage',
  data: () => ({
    formatISO,
    cookingClasses: [],
    generalClasses: [],
    allClasses: [],
    error: null
  }),
  async mounted() {
    try {
      axios
        .all([
          axios.get('https://strapi.nyk.mindex.cloud/cooking-classes'),
          axios.get('https://strapi.nyk.mindex.cloud/general-classes')
        ])
        .then(
          axios.spread((cookingClasses, generalClasses) => {
            this.cookingClasses = cookingClasses.data;
            this.generalClasses = generalClasses.data;
            this.allClasses = cookingClasses.data
              .concat(generalClasses.data)
              .filter(item => item.startTime >= formatISO(new Date()))
              .sort((a, b) => {
                let dateA = new Date(a.startTime);
                let dateB = new Date(b.startTime);
                return dateA - dateB;
              });
          })
        );
    } catch (error) {
      this.error = error;
    }
  },
  components: {
    Classes
  }
};
</script>

<style scoped>
.classPageRoot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
