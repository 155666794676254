<template>
  <v-container fluid pa-0>
    <v-footer dark padless>
      <v-card
        flat
        tile
        class="black lighten-1 white--text text-center footerCard"
      >
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>New York Kitchen</strong>
          <v-card-text class="footerText">800 South Main Street</v-card-text>
          <v-card-text class="footerText">Canandaigua, NY 14424</v-card-text>
        </v-card-text>
        <div class="phoneEmailWrapper">
          <v-btn class="phoneEmailStyle" href="tel:585-394-7070">
            <v-icon class="phoneEmailIcon">mdi-phone</v-icon>
            (585) 394-7070
          </v-btn>
          <v-btn class="phoneEmailStyle" href="mailto:info.desk@nykitchen.com">
            <v-icon class="phoneEmailIcon">mdi-email</v-icon>
            Email Us
          </v-btn>
        </div>
        <div style="display:inline-block; margin:auto">
          <a href="https://taste.ny.gov/ " target="_blank">
            <v-img class="tasteLogo" src="../assets/TasteNY_icon.png"></v-img>
          </a>
        </div>
        <v-card-text class="white--text pt-0">
          Copyright All Rights Reserved.
          <br />
          <a href="https://www.nykitchen.com/policies/">Privacy Policy</a>
          <br />
          <a href="https://www.nykitchen.com/accessibility/"
            >Website Accessibility</a
          >
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  name: 'Footer',
  data: () => ({})
};
</script>

<style scoped>
.tasteLogo {
  width: 200px;
}
.footerCard {
  width: 100%;
}
.footerText {
  margin: 10px 0px 10px 0px;
  padding: 0px;
}
.phoneEmailWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.phoneEmailStyle {
  margin: 0px 10px 10px 10px;
  text-transform: none;
}
.phoneEmailIcon {
  margin-right: 8px;
}
</style>
