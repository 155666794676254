<template>
  <v-container>
    <p class="text-h2 text-center font-weight-bold">
      Frequently Asked Questions
    </p>
    <div class="faqsWrapper">
      <div class="faqsSubWrapper">
        <p class="faqTitle">Are You a Culinary School?</p>
        <p class="faqResponse">
          We are neither a culinary school nor an institute. We are a 501(c)(3)
          nonprofit organization that showcases New York’s dynamic agriculture,
          wine, craft beer, and food industries. New York Kitchen’s
          state-of-the-art facility overlooking Canandaigua Lake features a
          variety of event spaces to explore, and experiences to guide you along
          your New York State culinary and craft beverage journey!
        </p>
      </div>
      <div class="faqsSubWrapper">
        <p class="faqTitle">What Can I Do at New York Kitchen?</p>
        <p class="faqResponse">
          There is so much to explore at the New York Kitchen! Each day, New
          York Kitchen drives its nonprofit mission forward through educational
          hands-on cooking and craft beverage pairing classes and programs,
          industry certification courses, and our 100% New York State Tasting
          Room--featuring wine, beer, cider and distilled spirits from across
          the Empire State. In addition to our educational classes and programs,
          New York Kitchen features a restaurant, the NYK Café, overlooking
          beautiful Canandaigua Lake, operated by our food and beverage partner,
          Best Beverage Catering. Are you looking for the perfect venue for a
          private event? New York Kitchen can help! Contact our front desk and
          we would be happy to arrange a tour of our breath-taking event spaces.
        </p>
      </div>
      <div class="faqsSubWrapper">
        <p class="faqTitle">How Do I Register for a Class?</p>
        <p class="faqResponse">
          You can register for a class online, by calling           <span>
            <a href="tel:5853947070">585.394.7070</a>
          </span>, or in
          person at New York Kitchen. Register online here and select the class
          you would like to attend. When you are ready to check out, proceed to
          the shopping cart and complete the registration and payment process as
          directed. You will receive an email confirmation once you are
          successfully registered. Have additional questions? Contact us at
          <span>
            <a href="tel:5853947070">585.394.7070</a>
          </span>
        </p>
      </div>
      <div class="faqsSubWrapper">
        <p class="faqTitle">
          Should I Still Sign Up for a Class if There is a Waitlist?
        </p>
        <p class="faqResponse">
          It is always a good idea to add your name to the waitlist for a class
          that is of interest to you. You do not need to submit payment
          information until a spot becomes available. If an opening becomes
          available, you will be notified by phone or email.
        </p>
      </div>
      <div class="faqsSubWrapper">
        <p class="faqTitle">
          What is Your Cancellation Policy?
        </p>
        <p class="faqResponse">
          Class ticket purchases are non-refundable or exchangeable for any
          reason. This is because New York Kitchen purchases materials and plans
          for classes based on the number of participants registered. However,
          if you’d like to offer your seat in that class to a friend or family
          member, we’d be happy to let them take your spot.
        </p>
      </div>
      <div class="faqsSubWrapper">
        <p class="faqTitle">
          Can I purchase Alcoholic Beverages to Bring into Class?
        </p>
        <p class="faqResponse">
          Yes, beverages may be purchased in our Tasting Room and from the
          full-service bar in the Restaurant.
        </p>
      </div>
      <div class="faqsSubWrapper">
        <p class="faqTitle">
          How can I purchase a Gift Card?
        </p>
        <p class="faqResponse">
          Gift Cards can be purchased
          <!-- <span
            ><a
              href="https://nykitchen.revelup.online/store/1/category/1/subcategory/2"
              >Online</a
            ></span
          > -->
          in person at the front desk, or by calling
          <span>
            <a href="tel:5853947070">585.394.7070.</a>
          </span>
        </p>
      </div>
      <div class="faqsSubWrapper">
        <p class="faqTitle">
          What can Gift Cards be Used for?
        </p>
        <p class="faqResponse">
          Gift Cards are redeemable for any of New York Kitchen's offerings and
          to make online purchases.
        </p>
          <!-- They can be purchased -->
          <!-- <span
            ><a
              href="https://nykitchen.revelup.online/store/1/category/1/subcategory/2"
              >here.</a
            ></span
          >
        </p> -->
      </div>
      <div class="faqsSubWrapper">
        <p class="faqTitle">
          What is the Dress Code at the Restaurant?
        </p>
        <p class="faqResponse">
          There is no formal dress code at the Restaurant. It is a fun, casual
          atmosphere.
        </p>
      </div>
      <div class="faqsSubWrapper">
        <p class="faqTitle">
          Is there a Corkage Fee at the Restaurant?
        </p>
        <p class="faqResponse">
          There is a $15 corkage fee.
        </p>
      </div>
      <div class="faqsSubWrapper">
        <p class="faqTitle">
          What Kind of Events can be Hosted at New York Kitchen?
        </p>
        <p class="faqResponse">
          From seasonal events, to weddings, corporate events, and special
          birthday celebrations, New York Kitchen is a one-of-a-kind place to
          host your private events. Explore our variety of event spaces
          <span><router-link to="/privateevents">here!</router-link></span>
        </p>
      </div>
      <div class="faqsSubWrapper">
        <p class="faqTitle">
          How can I Apply to Work at New York Kitchen?
        </p>
        <p class="faqResponse">
          New York Kitchen takes pride in our team. If you are interested in
          becoming a New York Kitchen team member, please email us by clicking <span><a href="mailto:tammy.dostie@nykitchen.com">here</a></span> to
          get information about available positions.
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Faqs",
};
</script>

<style scoped>
.faqsWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.faqsSubWrapper {
  display: flex;
  flex-direction: column;
}
.faqTitle {
  font-weight: bold;
}
.faqResponse {
}
</style>
