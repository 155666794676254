<template>
  <v-container>
    <div>
      <p class="text-h2 text-center font-weight-bold">
        Our Partners
      </p>

      <v-card
        class="d-md-flex justify-center align-center cardWrapper"
        elevation="2"
      >
        <div>
          <p class="text-h2 text-center font-weight-bold lakeHouseHeader">
            The Lake House
          </p>

          <v-card-text
            >The Lake House is the first hotel of its kind on the banks of
            Canandaigua Lake. The property’s picturesque buildings and
            landscaped grounds are the perfect settings to make new, lasting
            memories of outdoor adventures and romantic getaways. There are 124
            impeccably designed guest rooms, a timber frame event barn, an
            outdoor pool, and a destination spa. The hotel also offers a
            signature restaurant, Rose Tavern, featuring locally-sourced
            ingredients and stellar wines from the Finger Lakes, as well as the
            locally beloved lakeside Sand Bar. industries.
          </v-card-text>
          <v-card-text>
            Lovingly conceived of and created by a family of dreamers and doers,
            the property is a combination of nostalgic familiarity and modern
            design sensibilities, paying particular attention to sustainability.
            Your room was designed to integrate the area’s natural beauty and
            introduce the uninitiated to the charms of lake life. Nestled at the
            base of Canandaigua Lake, you’re now in the heart of the Finger
            Lakes. We suggest making the most of it with slow, sunny walks in
            the garden; long days spent sailing and swimming; cozy nights by the
            fire; and feasts of locally-grown ingredients and the
            internationally renowned wines produced just a short drive from
            here.
          </v-card-text>
        </div>
        <v-img class="cardImg" src="../assets/lake_house.jpg"></v-img>
      </v-card>
      <v-card
        class="d-md-flex justify-center align-center cardWrapper"
        elevation="2"
      >
        <v-img
          class="cardImg"
          src="../assets/finger_lakes_culinary.jpg"
        ></v-img>

        <div>
          <p class="text-h2 text-center font-weight-bold lakeHouseHeader">
            Finger Lakes Community College Culinary Arts
          </p>

          <v-card-text
            >Finger Lakes Community College is a place where opportunity awaits.
            It is a vibrant community that prepares students for lives and
            careers that are successful and fulfilling. At FLCC, you'll receive
            an affordable education that has so much to offer. You'll have
            opportunities to be involved in coursework, research, activities and
            student organizations. Your educational experience will challenge
            you to work hard, persevere, and be your best. FLCC is ideal for
            exploring your interests. It offers a variety of academic programs,
            and it is a hub for attaining knowledge and building skills.
            Together, the experiences you'll gain here will inspire you to
            achieve your future goals.
          </v-card-text>
        </div>
      </v-card>
      <v-card
        class="d-md-flex justify-center align-center cardWrapper"
        elevation="2"
      >
        <div>
          <p class="text-h2 text-center font-weight-bold lakeHouseHeader">
            del Lago Resort & Casino
          </p>
          <v-card-text
            >del Lago Resort & Casino is located in Seneca County just steps off
            Exit 41 of the New York State Thruway, the heart of the New York’s
            Finger Lakes region. We pride ourselves on Old-World Hospitality;
            our commitment to excellence and quality does not stop at our
            entrance. We treat our guests, our employees and our neighbors with
            the same high level of respect, we lead by example, act with
            integrity and continually seek ways in which we can improve. As our
            name implies, we are “of the lake.”
          </v-card-text>
          <v-card-text>
            While we are primarily a world-class resort and casino, our larger
            goal is to serve as a gateway to the Finger Lakes, inspiring tourism
            and growth throughout the region. It’s an ambitious goal, but we
            wouldn’t want it any other way!
          </v-card-text>
        </div>
        <v-img class="cardImg" src="../assets/del_lago_outside.jpg"></v-img>
      </v-card>
    </div>

    <div>
      <p class="text-h2 text-center font-weight-bold aboutHeader">
        Our Sponsors
      </p>
      <div class="sponsorLogoWrapper">
        <a class="sponsorLogo" href="https://www.cbrands.com/">
          <v-img src="../assets/constellation_logo.png"></v-img>
        </a>
        <a class="sponsorLogo" href="https://dellagoresort.com/">
          <v-img src="../assets/del_lago_logo.png"></v-img>
        </a>
        <a class="sponsorLogo" href="https://quailsummit.com/">
          <v-img src="../assets/stanns_logo.png"></v-img>
        </a>
        <a class="sponsorLogo" href="https://www.rochesterregional.org/">
          <v-img src="../assets/rgh_logo.png"></v-img>
        </a>
        <a class="sponsorLogo" href="https://www.cnbank.com/">
          <v-img src="../assets/cnb_logo.png"></v-img>
        </a>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Partners",
};
</script>

<style scoped>
.cardImg {
  height: 500px;
  width: 50%;
}

@media only screen and (max-width: 960px) {
  .cardImg {
    width: 100%;
    margin-bottom: 20px !important;
  }
}
@media only screen and (max-width: 600px) {
  .cardImg {
    max-height: 240px;
  }
  .sponsorLogoWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sponsorLogo {
    width: 200px !important;
  }
}
.cardImgTwo {
  max-width: 400px;
  width: 100%;
  margin: auto;
}
.cardWrapper {
  margin: 40px 0 40px 0;
}
.classesWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.teamRoot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.teamWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.teamCard {
  margin: 10px;
  width: 300px;
  height: 250px;
}
.aboutTitle {
  font-weight: bold;
  text-align: center;
  padding-bottom: 0;
  margin-top: 10px;
  font-size: 1rem;
  color: black !important;
}
.aboutName {
  justify-content: center;
  color: white;
  background-color: black;
}
.aboutNameBoard {
  justify-content: center;
  color: white;
  background-color: #82b1ff;
}
.aboutEmail {
  text-align: center;
  margin-top: 10px;
}

.aboutEmail a {
  text-decoration: none;
  color: black;
}
.aboutEmailIcon {
  margin: 0 10px 0 10px;
}
.aboutTitleEmailWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.aboutHeader {
  font-size: 2.5rem !important;
}
.lakeHouseHeader {
  margin: 15px;
  font-size: 2.5rem !important;
}
.sponsorLogoWrapper {
  display: flex;
  /* flex-wrap: wrap; */
}

.sponsorLogo {
  width: 20%;
}
</style>
