import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../components/Landing";
import ClassesPage from "../components/ClassesPage";
import EventsPage from "../components/EventsPage";
import TastingRoom from "../components/TastingRoom";
import PrivateEvents from "../components/PrivateEvents";
import Restaurant from "../components/Restaurant";
import AboutUs from "../components/AboutUs";
import JuniorChef from "../components/JuniorChef";
import ClassesDetails from "../components/ClassesDetails";
import Faqs from "../components/Faqs";
import Partners from "../components/Partners";
import BlogsPage from "../components/BlogsPage";
import BlogDetails from "../components/BlogDetails";
import EventsDetails from "../components/EventsDetails";
import ThankYou from "../components/ThankYou";
import GeneralClassesDetails from '../components/GeneralClassesDetails';
import Terrace from '../components/Terrace';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Landing,
      meta: { title: "New York Kitchen | Home" },
    },
    {
      path: "/classes",
      name: "ClassesPage",
      component: ClassesPage,
      meta: { title: "New York Kitchen | Classes" },
    },
    {
      path: "/events",
      name: "EventsPage",
      component: EventsPage,
      meta: { title: "New York Kitchen | Events" },
    },
    {
      path: "/tastingroom",
      name: "TastingRoom",
      component: TastingRoom,
      meta: { title: "New York Kitchen | Tasting Room" },
    },
    {
      path: "/privateevents",
      name: "PrivateEvents",
      component: PrivateEvents,
      meta: { title: "New York Kitchen | Private Events" },
    },
    {
      path: "/restaurant",
      name: "Restaurant",
      component: Restaurant,
      meta: { title: "New York Kitchen | Restaurant" },
    },
    {
      path: "/about",
      name: "AboutUs",
      component: AboutUs,
      meta: { title: "New York Kitchen | About Us" },
    },
    {
      path: "/terrace",
      name: "Terrace",
      component: Terrace,
      meta: { title: "New York Kitchen | Terrace" },
    },
    {
      path: "/partners",
      name: "Partners",
      component: Partners,
      meta: { title: "New York Kitchen | Partners" },
    },
    {
      path: "/faqs",
      name: "Faqs",
      component: Faqs,
      meta: { title: "New York Kitchen | FAQs" },
    },
    {
      path: "/juniorchef",
      name: "JuniorChef",
      component: JuniorChef,
      meta: { title: "New York Kitchen | Junior Chef Initiative" },
    },
    {
      path: "/classesdetails/:id",
      name: "ClassesDetails",
      component: ClassesDetails,
      meta: { title: "New York Kitchen | Class Details" },
      props: true,
    },
    {
      path: '/generalclassesdetails/:id',
      name: 'GeneralClassesDetails',
      component: GeneralClassesDetails,
      meta: { title: 'New York Kitchen | General Class Details' },
      props: true
    },
    {
      path: "/blogs",
      name: "BlogsPage",
      component: BlogsPage,
      meta: { title: "New York Kitchen | Blogs" },
    },
    {
      path: "/blogdetails/:id",
      name: "BlogDetails",
      component: BlogDetails,
      meta: { title: "New York Kitchen | Blog Details" },
    },
    {
      path: "/eventsdetails/:id",
      name: "EventsDetails",
      component: EventsDetails,
      meta: { title: "New York Kitchen | Event Details" },
      props: true,
    },
    {
      path: "/thankyou",
      name: "ThankYou",
      component: ThankYou,
      meta: { title: "New York Kitchen | Thank You!" },
    },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
