<template>
  <v-container class="blogContainer">
    <div class="blogWrapper">
      <div>
        <p class="text-h3 text-left font-weight-bold">{{ blogDetails.name }}</p>
        <p>{{ formatedDate }}</p>
      </div>
      <v-img
        max-height="500"
        class="blogImage"
        :src="`${blogDetails.image.url}`"
      >
      </v-img>
      <div class="blogContentWrapper">
        <p>
          {{ blogDetails.blogContent }}
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';
import format from 'date-fns/format';

export default {
  name: 'BlogDetails',
  data: () => ({
    blogDetails: [],
    formatedDate: ''
  }),
  methods: {
    formatDate(rawDate) {
      let newDate = format(new Date(rawDate), 'iii, LLL dd yyyy h:mm aaa');
      this.formatedDate = newDate;
    }
  },
  async mounted() {
    try {
      const response = await axios.get(
        `https://strapi.nyk.mindex.cloud/blogs/${this.$route.params.id}`
      );
      this.blogDetails = response.data;
      this.formatDate(response.data.published_at);
    } catch (error) {
      this.error = error;
    }
  }
};
</script>

<style scoped>
p {
  color: black;
}
.blogImage {
  /* margin: auto; */
}
.blogWrapper {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.blogContainer {
  display: flex;
  justify-content: center;
}
.blogContentWrapper {
  margin: 30px 0 20px 0;
}
</style>
