<template>
  <v-container>
    <Calendar :calendartype=2>
    </Calendar>
     <div class="eventsRoot">
      <div class="eventsCardWrapper">
        <v-card
          :loading="loading"
          class="my-12 eventCard"
          max-width="374"
          v-for="event in events"
          :key="event.id"
        >
          <v-img height="350" :src="`${event.image.url}`"></v-img>

          <v-card-title>{{ event.name }}</v-card-title>

          <v-card-text>
            <v-row align="center" class="mx-0">
              <v-card-text>
                {{
                  format(new Date(event.startTime), "iii, LLL dd yyyy h:mm aaa")
                }}
                -
                {{
                  format(new Date(event.endTime), "iii, LLL dd yyyy h:mm aaa")
                }}</v-card-text
              >
            </v-row>

            <div>{{ event.description }}</div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-title>Sign Up Now!</v-card-title>

          <v-card-text v-if="event.tickets > 0">
            {{ event.tickets }} tickets left
          </v-card-text>

          <v-card-actions class="reserveButtonWrapper">
            <router-link
              class="eventsDetails"
              :to="`/eventsdetails/${event.id}`"
            >
              <v-btn
                class="reserveButton"
                color="white lighten-2"
                text
                outlined
              >
                Reserve Your Spot!
              </v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import format from "date-fns/format";
import Calendar from './Calendar';

export default {
  name: "Events",
  props: ["events"],
  data: () => ({
    loading: false,
    selection: 1,
    format,
  }),
  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
  },
  components: {
    Calendar
  }
};
</script>

<style scoped>
.eventsRoot {
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reserveButtonWrapper {
  justify-content: center;
  margin-bottom: 10px;
}

.eventsDetails {
  text-decoration: none;
}

.reserveButton {
  background-color: #4caf50;
  text-transform: none;
  justify-content: center;
  margin-bottom: 10px;
}

.eventsCardWrapper {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 960px) {
  .eventsCardWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.eventCard {
  margin: 20px;
}
</style>
