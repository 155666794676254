<template>
  <v-app>
    <Nav/>
    <v-main class="mainRootWrapper">
      <router-view></router-view>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Footer from "./components/Footer";
import Nav from "./components/Nav";
export default {
  name: "App",

  components: {
    Footer,
    Nav
  }
};
</script>
<style>
  *{
    word-break: normal !important;
  }

  .mainRootWrapper {
    margin-top: 64px !important;
  }
</style>
