<template>
  <v-container fluid pa-0>
    <div class="blogsRoot">
      <div class="blogsCardWrapper">
        <v-card
          max-width="374"
          width="300"
          class="my-12 blogCard"
          v-for="blog in blogs"
          :key="blog.id"
        >
          <router-link :to="`/blogdetails/${blog.id}`">
            <v-card-title>{{ blog.name }}</v-card-title>
            <v-card-text>{{
              format(new Date(blog.published_at), 'iii, LLL dd yyyy h:mm aaa')
            }}</v-card-text>
            <v-card-text style="min-height:75px">{{
              blog.description
            }}</v-card-text>
            <v-img height="350" :src="`${blog.image.url}`"> </v-img>
          </router-link>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import format from 'date-fns/format';

export default {
  name: 'Blogs',
  props: ['blogs'],
  data: () => ({
    format
  })
};
</script>

<style scoped>
p {
  color: black;
}
div {
  color: black;
}
a {
  text-decoration: none;
}
.blogsRoot {
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blogCard {
  margin: 20px;
}
.blogsCardWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* width: 110%; */
}
</style>
