<template>
  <v-container class="thankYouPageRoot">
    <p class="text-h3 text-center font-weight-bold">
      Thank you for your purchase!
    </p>
    <p>You will receive an email once your payment is finished processing.</p>
  </v-container>
</template>

<script>
export default {
  name: "ThankYou",
};
</script>

<style scoped>
.thankYouPageRoot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;
}
</style>
