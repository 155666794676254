<template>
  <div>
    <NewsletterModal v-bind:dialog="dialog" />
    <v-container>
      <v-card
        class="d-md-flex justify-center align-center cardWrapperTop"
        elevation="2"
      >
        <v-img
          class="cardImg"
          src="https://nyk-frontend.s3.amazonaws.com/GTP_NYK-OUTDOORS-7067-SQUARE_compressed.jpg"
        ></v-img>
        <div>
          <p class="text-h2 text-center font-weight-bold">Our Story</p>
          <v-card-text
            >Located in the heart of the Finger Lakes, New York Kitchen is a
            501(c)(3) nonprofit organization, originating from the collaboration
            of Constellation Brands, Wegmans Food Markets, Rochester Institute
            of Technology, and the New York Wine and Grape Foundation. These
            dynamic forces came together with a shared passion and mission to
            create a gateway for people around the world to experience New
            York’s incredible agriculture and viticulture industries.
          </v-card-text>
          <v-card-text>
            On June 16, 2006, we opened our doors as “The New York State Wine &
            Culinary Center,” and on April 17, 2018 we re-branded as “New York
            Kitchen.” Since inception our goal has remained the same: to guide
            guests on a journey centered on exploring New York’s remarkable
            agriculture, craft beverage, and culinary arts.
          </v-card-text>
          <div>
            <v-img
              src="../assets/ny_kitchen_sigLogo_cropped.jpg"
              class="cardImgTwo"
            ></v-img>
          </div>
        </div>
      </v-card>
      <v-card
        class="d-md-flex justify-center align-center cardWrapper"
        elevation="2"
      >
        <div>
          <p class="text-h2 text-center font-weight-bold">Today</p>
          <v-card-text
            >New York Kitchen believes that a strong connection between people,
            place, and produce is essential to promoting healthy lifestyles and
            thriving communities. We use the stories of agriculture, food, and
            beverage to inspire creative exploration to educate, excite, and
            engage the world in a celebration of the incredible bounty of New
            York State.
          </v-card-text>
          <v-card-text>
            Each day, New York Kitchen drives this mission forward through its
            educational hands-on cooking and craft beverage pairing classes and
            programs, industry certification courses, and 100% New York State
            Tasting Room. Learn more about our Junior Chef Initiative
            <span><router-link to="/juniorchef">here</router-link></span
            >.
          </v-card-text>
        </div>
        <v-img
          class="cardImg"
          src="https://nyk-frontend.s3.amazonaws.com/homepage_photo_2.jpg"
        ></v-img>
      </v-card>
    </v-container>

    <div class="classesWrapper">
      <p v-if="allClasses.length > 0" class="text-h4 align eventsHeader">
        Upcoming Classes & Happenings
      </p>
      <div class="calendar-wrapper">
        <Classes v-bind:mainpage="true" v-bind:allClasses="allClasses" />
      </div>
      <router-link
        to="/classes"
        tag="span"
        style="cursor: pointer; color: white"
      >
        <v-btn class="seeAllClassesButton"> See All Classes </v-btn>
      </router-link>
    </div>
    <v-container>
      <div>
        <p class="text-h4 giftcardHeader">Looking for a Special Gift?</p>
        <v-card
          class="d-md-flex justify-center align-center cardWrapper"
          elevation="2"
        >
          <v-img class="cardImg" src="../assets/nyk_giftcard.jpg"></v-img>
          <div>
            <v-card-text>
              Give the gift of interactive, state of the art culinary and
              beverage classes, New York State wine and beer tasting experiences
              or a visit to the Restaurant. New York Kitchen Gift Cards can be
              used in any venue of NYK.
            </v-card-text>
            <v-card-text>
              Gift Cards are redeemable for any of the Kitchen's offerings and
              may be purchased in person or by phone at
              <span><a href="tel:+1-585-394-7070">(585)394-7070</a></span
              >!
            </v-card-text>
            <!-- <div class="giftcardWrapper">
              <v-btn
                color="black lighten-2"
                class="white--text"
                height="50px"
                href="https://nykitchen.revelup.online/store/1"
              >
                Purchase Giftcard
              </v-btn>
            </div> -->
          </div>
        </v-card>
      </div>
      <div class="classesWrapper">
        <p v-if="blogs.length > 0" class="text-h4 align eventsHeader">
          Recent Blogs
        </p>
        <Blogs v-bind:blogs="blogs" />
        <router-link
          to="/blogs"
          tag="span"
          style="cursor: pointer; color: white"
        >
          <v-btn class="seeAllClassesButton"> Read All of Our Blogs </v-btn>
        </router-link>
      </div>
    </v-container>
    <NewsletterBanner />
  </div>
</template>

<script>
import Classes from "./Classes";
import Blogs from "./Blogs";
import NewsletterModal from "./NewsletterModal";
import NewsletterBanner from "./NewsletterBanner";

import axios from "axios";
import formatISO from "date-fns/formatISO";

export default {
  name: "Landing",

  data: () => ({
    formatISO,
    dialog: false,
    allClasses: [],
    allevents: [],
    blogs: [],
    events: [],
    error: null,
  }),

  async mounted() {
    try {
      axios
        .all([
          axios.get("https://strapi.nyk.mindex.cloud/cooking-classes"),
          axios.get("https://strapi.nyk.mindex.cloud/general-classes"),
          axios.get("https://strapi.nyk.mindex.cloud/blogs"),
        ])
        .then(
          axios.spread((classes, generalClasses, blogs) => {
            //limit classes and events to 3, will need to sort by closest date
            // console.log('classes', classes.data);
            // console.log('blogs', blogs.data);
            // this.generalClasses = generalClasses.data;
            // this.cookingClasses = classes.data;

            this.allClasses = classes.data
              .concat(generalClasses.data)
              .filter((item) => item.startTime >= formatISO(new Date()))
              .sort((a, b) => {
                let dateA = new Date(a.startTime);
                let dateB = new Date(b.startTime);
                return dateA - dateB;
              })
              .slice(0, 3);
            // console.log(this.allClasses);
            this.blogs = blogs.data
              .sort((a, b) => {
                let dateA = new Date(a.published_at);
                let dateB = new Date(b.published_at);
                return dateA - dateB;
              })
              .slice(0, 3);
          })
        );
    } catch (error) {
      this.error = error;
    }
    function getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
    function setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    let cookie = getCookie("visted");
    if (cookie !== "true") {
      this.$nextTick(function () {
        setTimeout(
          function (scope) {
            scope.dialog = true;
          },
          3000,
          this
        );
      });
      setCookie("visted", true, 1);
    }
  },
  components: {
    Classes,
    Blogs,
    NewsletterModal,
    NewsletterBanner,
  },
};
</script>

<style scoped>
.cardImg {
  height: 500px;
  width: 50%;
}
.cardImgTwo {
  max-width: 400px;
  width: 100%;
  margin: auto;
}
.cardWrapper {
  margin: 40px 0 40px 0;
}
.cardWrapperTop {
  margin: 20px 0 40px 0;
}
.classesWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.eventsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}
.eventsHeader {
  margin: 30px 0 0 0;
  font-weight: bold;
}
.giftcardHeader {
  text-align: center;
}
.giftcardWrapper {
  margin: 20px;
}
.giftcardPriceSelect {
  width: 300px;
}
.calendar-wrapper {
  width: 90%;
}

.seeAllClassesButton {
  height: 60px !important;
  width: 300px;
  margin: 10px 10px 50px 10px;
  text-transform: none;
  background-color: black !important;
  color: white;
}

@media only screen and (max-width: 960px) {
  .cardImg {
    width: 100%;
    margin-bottom: 20px !important;
  }
}
@media only screen and (max-width: 600px) {
  .cardImg {
    max-height: 240px;
  }
  .cardWrapperTop {
    margin: 0 0 40px 0;
  }
}
</style>
