<template>
  <v-container>
    <p class="text-h2 text-center font-weight-bold">The Restaurant</p>
    <v-card
      class="cardWrapper d-md-flex justify-center align-center"
      elevation="2"
    >
      <v-img class="cardImg" src="../assets/restaurant2.jpg"></v-img>
      <div>
        <v-card-title>Experience the Best New York has to Offer</v-card-title>
        <v-card-text>
          Our chefs work directly with local area farmers to provide top notch,
          farm-to-table fare with a New York State twist, sure to provide an
          unforgettable dining experience. Our beverage curators will “craft up”
          the perfect New York State beverages with care, to ensure you and your
          guests have a true New York State tasting experience.
        </v-card-text>
      </div>
    </v-card>
    <a
      class="instagram-handle"
      href="https://www.instagram.com/nyk_cafeandevents/?hl=en"
      target="_blank"
    >
      <v-icon size="30px">mdi-instagram</v-icon>
      <p>/nyk_cafeandevents</p>
    </a>
    <div class="text-center hoursWrapper">
      <p style="font-weight: bold;">Hours of Operation:</p>
      <v-card-text v-for="hour in hours" :key="hour.id">
        <b>{{ hour.day_of_the_week }} </b>: {{ hour.hours_or_status }}
      </v-card-text>
    </div>
    <div class="menuButtonWrapper">
      <v-btn
        v-for="cafeMenu in cafeMenus"
        :key="cafeMenu.order"
        color="black lighten-2"
        class="white--text menuButton"
        height="50px"
        :href="`${cafeMenu.menu_upload[0].url}`"
        target="_blank"
      >
        {{ cafeMenu.menu_title }}
      </v-btn>
    </div>
    <v-card class="d-md-flex justify-center align-center" elevation="2">
      <div>
        <v-card-title>Experience the Best New York has to Offer</v-card-title>
        <v-card-text>
          Located on the north end of beautiful Canandaigua Lake, we’re the
          perfect destination for your private event or gathering. With
          awe-inspiring views, and a breathtaking atmosphere, this will be an
          event your guests won’t likely forget. Our team of hospitality experts
          will work with you every step of the way to find the perfect location
          for your celebration. Each of our event spaces are unique in their own
          way, and can be tailored to fit the setting and atmosphere you’re
          looking for.
        </v-card-text>
        <v-card-text>
          Our chefs work directly with local area farmers to provide top notch,
          farm-to-table fare with a New York State twist, sure to provide an
          unforgettable dining experience. Our beverage curators will “craft up”
          the perfect New York State beverages with care, to ensure you and your
          guests have a true New York State tasting experience.
        </v-card-text>
        <v-card-text>
          In all of our weddings, events, meetings, and private celebrations, we
          pride ourselves in showcasing the best of what New York has to offer,
          and we can’t wait to share it with you! Ready to celebrate? Let’s
          start planning!
        </v-card-text>
      </div>
      <v-img class="cardImg" src="../assets/nycafe_1.jpg"></v-img>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Restaurant",

  data: () => ({
    hours: [],
    cafeMenus: [],
    error: null,
  }),
  async mounted() {
    try {
      axios
        .all([
          axios.get("https://strapi.nyk.mindex.cloud/restaurant-hours"),
          axios.get("https://strapi.nyk.mindex.cloud/menus"),
        ])
        .then(
          axios.spread((hours, cafeMenus) => {
            this.hours = hours.data.sort((a, b) =>
              a.order > b.order ? 1 : -1
            );
            this.cafeMenus = cafeMenus.data
              .sort((a, b) => (a.order > b.order ? 1 : -1))
              .map((menu) => menu);
          })
        );
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped>
.cardWrapper {
  margin: 40px 0 40px 0;
}
.cardImg {
  height: 500px;
  width: 50%;
}
.menuButtonWrapper {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: auto;
  margin-bottom: 30px;
}

.menuButton {
  width: 20vw;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 16px;
  margin: 10px;
}
.hoursWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.instagram-handle {
  display: inline-flex;
  width: 100%;
  font-size: 130%;
  justify-content: center;
  text-decoration: none;
  margin-bottom: 2%;
}

.instagram-handle p {
  color: #757575;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
}

.instagram-handle p:hover {
  color: #000000;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
}

.instagram-handle i:hover {
  color: #000000;
}

.closingUpdate {
  text-align: center;
  color: red;
  font-style: italic;
}

@media only screen and (max-width: 960px) {
  .cardImg {
    width: 100%;
    margin-bottom: 20px !important;
  }
}
@media only screen and (max-width: 600px) {
  .cardImg {
    max-height: 240px;
  }

  .menuButton {
    width: 100%;
    margin: 20px auto 20px auto;
    border-radius: 16px;
  }
  .menuButtonWrapper {
    flex-direction: column;
    width: 100%;
  }
}
</style>
