<template>
  <v-container class="d-md-flex-column justify-center align-center">
    <div class="d-md-flex justify-center">
      <p class="text-h2 text-center font-weight-bold">Tasting Room</p>
    </div>
    <v-card
      class="d-md-flex justify-center align-center cardWrapperTop"
      elevation="2"
    >
      <v-img
        class="cardImg"
        src="https://nyk-frontend.s3.amazonaws.com/GTP_NYK-A-5918-min.jpg"
      ></v-img>
      <div>
        <v-card-title>New York on Tap</v-card-title>
        <v-card-text>
          Join us in the Tasting Room for a tasting experience like no other.
          New York Kitchen’s Tasting Room features 60 different and ever
          changing NYS wines, 10 taps highlighting seasonal craft beers and
          ciders, distilled spirits, as well as non-alcoholic beverages. Sip and
          savor our diverse flights in a fun, relaxing atmosphere.
        </v-card-text>
        <v-card-text>
          Don’t forget to select some of your favorites to indulge in at home!
          During your visit, our Tasting Room team can help you find a new wine,
          craft beer or spirit to enjoy later.
        </v-card-text>
      </div>
    </v-card>
    <!-- <h2 class="tastingHeaderText"></h2>
        <div class="d-md-flex justify-space-around craftWrapper">
            <h3 class="tastingHeaderText">Craft Beer</h3> 
            <h3 class="tastingHeaderText">Craft Wine</h3>
        </div> -->
    <v-card
      class="d-md-flex justify-center align-center cardWrapperTop"
      elevation="2"
    >
      <div class="text-center hoursText">
        <v-card-title class="hoursTitle">HOURS OF OPERATION</v-card-title>
        <v-card-text v-for="hour in hours" :key="hour.id">
          <b>{{ hour.day_of_the_week }} </b>: {{ hour.hours_or_status }}
        </v-card-text>
        <v-card-text>
          **Hours subject to change
        </v-card-text>
      </div>
      <v-img class="cardImg" src="../assets/about_us_bar2.jpg"></v-img>
    </v-card>
    <div class="text-center covidWrapper">
      <!-- <p class="covidText cardWrapperTop">
        In order to adhere to social distancing guidelines, the New York Kitchen
        Tasting Room can accommodate 10 guests at any time. Walk-ins are welcome
        however, to guarantee service, please call 585-394-7070 x 135 to make a
        reservation for your party and avoid a wait time. We look forward to
        seeing you soon!
      </p> -->

      <p>
        Walk-ins welcome. To make a reservation for groups 6 and above, please call
        <a href="tel:5853947070">585-394-7070</a> x 135. We look forward to hosting you!
      </p>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Events",

  data: () => ({
    hours: [],
    error: null,
  }),
  async mounted() {
    try {
      const response = await axios.get(
        "https://strapi.nyk.mindex.cloud/tasting-room-hours"
      );
      this.hours = response.data;
      //sort hour objects by id so front end can loop through and display
      console.log(response.data.sort((a, b) => (a.order > b.order ? 1 : -1)));
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped>
.hoursText {
  width: 50%;
}
.hoursTitle {
  display: block;
}
.covidWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.covidText {
  max-width: 800px;
}
.tastingHeaderText {
  text-align: center;
  margin: 20px;
}
.craftWrapper {
  margin: 20px 0 20px 0;
}
.cardImg {
  height: 500px;
  width: 50%;
}
.cardWrapperTop {
  margin: 20px 0 40px 0;
}
@media only screen and (max-width: 960px) {
  .hoursText {
    width: 100%;
  }
  .cardImg {
    width: 100%;
  }
}
</style>
