<template>
  <v-container v-if="ticketProps" class="vld-parent">
    <loading
      :active.sync="paymentIsLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    ></loading>
    <h1 class="ticketStepHeader">Purchase Your Ticket!</h1>
    <v-stepper v-model="ticketProps.step">
      <v-stepper-header>
        <v-stepper-step :complete="ticketProps.step > 1" step="1">
          Party Details
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="ticketProps.step > 2" step="2">
          Entrees & Add-Ons
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="ticketProps.step > 3" step="3">
          Seat Selection
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4">
          Payment & Confirmation
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- --------------Step 1-------------------- -->
        <v-stepper-content step="1">
          <v-form v-model="stepOneValid" ref="stepOneForm" lazy-validation>
            <v-card class="mb-12">
              <div class="ticketTextWrapper">
                <p>
                  How many people are in your party?*
                </p>
              </div>

              <div class="partySizeWrapper">
                <v-text-field
                  v-model="partySize"
                  :rules="partySizeRules"
                  required
                  placeholder="0"
                  single-line
                  disabled
                  solo
                  class="partySizeTextField"
                ></v-text-field>
                <v-btn
                  v-if="ticketProps.availableTickets >= 1"
                  @click="increasePartySize"
                  >+
                </v-btn>
                <v-btn v-if="partySize > 0" @click="decreasePartySize">-</v-btn>
              </div>

              <p>Remaining tickets: {{ ticketProps.availableTickets }}</p>
            </v-card>

            <v-btn
              color="primary"
              @click="getCurrentTicketCount(ticketProps.step)"
            >
              Continue
            </v-btn>
          </v-form>
        </v-stepper-content>
        <!-- --------------Step 2-------------------- -->
        <v-stepper-content step="2">
          <v-form v-model="stepTwoValid" ref="stepTwoForm" lazy-validation>
            <v-card class="mb-12" minHeight="200px">
              <div>
                <p>Ticket Summary & Prices</p>
                <v-card
                  v-for="(item, i) in ticketProps.pairTickets"
                  :key="i"
                  class="pairSingleTicketWrapper"
                >
                  <p class="ticketTypeHeader">Pair Ticket {{ i + 1 }}</p>
                  <div class="ticketNameEmailWrapperPair">
                    <p>
                      Please provide both names and emails for this ticket:
                    </p>
                    <!--TODO add this data to Strapi ticketCLasses component -->

                    <!--TODO add v-model to each text field to ensure form is completed prior to next step. See ticketFormValid-->
                    <v-text-field
                      class="ticketNameEmailFieldPair"
                      solo
                      label="First Name"
                      required
                      :rules="firstNameRules"
                    >
                    </v-text-field>
                    <v-text-field
                      class="ticketNameEmailFieldPair"
                      solo
                      label="Last Name"
                      required
                      :rules="lastNameRules"
                    >
                    </v-text-field>
                    <v-text-field
                      class="ticketNameEmailFieldPair"
                      solo
                      label="Email"
                      required
                      :rules="emailRules"
                    >
                    </v-text-field>
                    <br />
                    <v-text-field
                      class="ticketNameEmailFieldPair"
                      solo
                      label="First Name"
                      required
                      :rules="firstNameRules"
                    >
                    </v-text-field>
                    <v-text-field
                      class="ticketNameEmailFieldPair"
                      solo
                      label="Last Name"
                      required
                      :rules="lastNameRules"
                    >
                    </v-text-field>
                    <v-text-field
                      class="ticketNameEmailFieldPair"
                      solo
                      label="Email"
                      required
                      :rules="emailRules"
                    >
                    </v-text-field>
                  </div>
                  <div
                    v-if="
                      ticketProps.ticketTypes.entreeOne.name ||
                        ticketProps.ticketTypes.entreeTwo.name
                    "
                  >
                    <p>
                      Select your entree type:
                    </p>
                    <v-radio-group
                      @change="updateEntrees"
                      row
                      class="entreeWrapper"
                    >
                      <v-radio
                        class="entreeDetails"
                        :label="
                          `${
                            ticketProps.ticketTypes.entreeOne.name
                          } - $${ticketProps.ticketTypes.entreeOne.price * 2}`
                        "
                        :value="`pair${i}-1`"
                      >
                      </v-radio>
                      <div
                        class="entreeDetails"
                        v-if="ticketProps.ticketTypes.entreeTwo.name"
                      >
                        <v-radio
                          class="entreeDetails"
                          :label="
                            `${
                              ticketProps.ticketTypes.entreeTwo.name
                            } - $${ticketProps.ticketTypes.entreeTwo.price * 2}`
                          "
                          :value="`pair${i}-2`"
                        >
                        </v-radio>
                      </div>
                    </v-radio-group>
                  </div>
                </v-card>
                <div>
                  <v-card
                    v-for="(item, i) in ticketProps.singleTickets"
                    :key="i"
                    class="pairSingleTicketWrapper"
                  >
                    <p class="ticketTypeHeader">Single Ticket {{ i + 1 }}</p>
                    <div class="ticketNameEmailWrapper">
                      <p>
                        Please provide your name and email for this ticket:
                      </p>

                      <v-text-field
                        class="ticketNameEmailField"
                        solo
                        label="First Name"
                        :rules="firstNameRules"
                      >
                      </v-text-field>
                      <v-text-field
                        class="ticketNameEmailField"
                        solo
                        label="Last Name"
                        :rules="lastNameRules"
                      >
                      </v-text-field>
                      <v-text-field
                        class="ticketNameEmailField"
                        solo
                        label="Email"
                        :rules="emailRules"
                      >
                      </v-text-field>
                    </div>
                    <p>
                      Select your entree type:
                    </p>
                    <v-radio-group
                      @change="updateEntrees"
                      row
                      class="entreeWrapper"
                      :rules="entreeSelectedRules"
                    >
                      <v-radio
                        class="entreeDetails"
                        :label="
                          `${ticketProps.ticketTypes.entreeOne.name} - $${ticketProps.ticketTypes.entreeOne.price}`
                        "
                        :value="`single${i}-1`"
                      >
                      </v-radio>
                      <div class="entreeDetails">
                        <v-radio
                          class="entreeDetails"
                          v-if="ticketProps.ticketTypes.entreeTwo.name"
                          :label="
                            `${ticketProps.ticketTypes.entreeTwo.name} - $${ticketProps.ticketTypes.entreeTwo.price}`
                          "
                          :value="`single${i}-2`"
                        >
                        </v-radio>
                      </div>
                    </v-radio-group>
                  </v-card>
                </div>
              </div>
              <div
                v-if="
                  ticketProps.ticketTypes.addOnOne.name ||
                    ticketProps.ticketTypes.addOnTwo.name
                "
              >
                <p>Select Add-ons:</p>
                <!--Add add ons with checkboxes that add totalTicketPrice OR addOnPrice array -->
                <v-checkbox
                  @change="updateAddOns"
                  v-model="addOnSelected"
                  :label="
                    `${ticketProps.ticketTypes.addOnOne.name} - $${ticketProps.ticketTypes.addOnOne.price}`
                  "
                  :value="`${ticketProps.ticketTypes.addOnOne.price}-1`"
                >
                </v-checkbox>
                <v-checkbox
                  @change="updateAddOns"
                  v-model="addOnSelected"
                  :label="
                    `${ticketProps.ticketTypes.addOnTwo.name} - $${ticketProps.ticketTypes.addOnTwo.price}`
                  "
                  :value="`${ticketProps.ticketTypes.addOnTwo.price + 1}-2`"
                >
                </v-checkbox>
              </div>
            </v-card>

            <v-btn
              color="primary"
              @click="getCurrentTicketCount(ticketProps.step)"
            >
              Continue
            </v-btn>

            <v-btn @click="prevStepReset" text>
              Back
            </v-btn>
          </v-form>
        </v-stepper-content>
        <!-- --------------Step 3-------------------- -->
        <v-stepper-content step="3">
          <v-form v-model="stepThreeValid" ref="stepThreeForm" lazy-validation>
            <v-card class="mb-12" height="auto">
              <p>Select your seating preference:</p>
              <p>
                Please note that seating is reserved on a fist come first serve
                basis. Your seating choice is
                <span class="seatingPreferenceText">not guaranteed</span>
                however, we will do our best to seat you according to you
                seating preference.
              </p>
              <div>
                <v-img
                  src="../assets/nyk_seating_chart.png"
                  height="450px"
                  contain
                >
                </v-img>
                <div v-if="ticketProps.pairTickets > 0">
                  <v-card
                    v-for="(item, i) in ticketProps.pairTickets"
                    :key="i"
                    class="pairSingleTicketWrapper"
                  >
                    <div>
                      <p style="font-weight: bold;">Pair Ticket {{ i + 1 }}</p>
                      <div>
                        <v-radio-group
                          row
                          style="display:flex; margin: 10px 0px 10px 0px"
                          v-model="pairTicketSeats[i]"
                          @change="addSeatingPreference"
                          :rules="seatingPreferenceRules"
                        >
                          <v-radio
                            label="C1 & C2"
                            :value="`pairTicket${i}-C1C2`"
                          >
                          </v-radio>
                          <v-radio
                            label="C3 & C4"
                            :value="`pairTicket${i}-C3C4`"
                          >
                          </v-radio>
                          <v-radio
                            label="C5 & C6"
                            :value="`pairTicket${i}-C5C6`"
                          >
                          </v-radio>
                          <v-radio
                            label="C7 & C8"
                            :value="`pairTicket${i}-C7C8`"
                          >
                          </v-radio>
                        </v-radio-group>
                      </div>
                      <div>
                        <v-radio-group
                          row
                          style="display:flex; flex-direction: row; margin: 10px 0px 10px 0px"
                          v-model="pairTicketSeats[i]"
                          @change="addSeatingPreference"
                          :rules="seatingPreferenceRules"
                        >
                          <v-radio
                            label="B1 & B2"
                            :value="`pairTicket${i}-B1B2`"
                          >
                          </v-radio>
                          <v-radio
                            label="B3 & B4"
                            :value="`pairTicket${i}-B3B4`"
                          >
                          </v-radio>
                          <v-radio
                            label="B5 & B6"
                            :value="`pairTicket${i}-B5B6`"
                          >
                          </v-radio>
                          <v-radio
                            label="B7 & B8"
                            :value="`pairTicket${i}-B7B8`"
                          >
                          </v-radio>
                        </v-radio-group>
                      </div>
                      <div>
                        <v-radio-group
                          row
                          style="display:flex; flex-direction: row; margin: 10px 0px 10px 0px"
                          v-model="pairTicketSeats[i]"
                          @change="addSeatingPreference"
                          :rules="seatingPreferenceRules"
                        >
                          <v-radio
                            label="A1 & A2"
                            :value="`pairTicket${i}-A1A2`"
                          >
                          </v-radio>
                          <v-radio
                            label="A3 & A4"
                            :value="`pairTicket${i}-A3A4`"
                          >
                          </v-radio>
                          <v-radio
                            label="A5 & A6"
                            :value="`pairTicket${i}-A5A6`"
                          >
                          </v-radio>
                          <v-radio
                            label="A7 & A8"
                            :value="`pairTicket${i}-A7A8`"
                          >
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </v-card>
                </div>

                <div>
                  <v-card
                    v-for="(item, i) in ticketProps.singleTickets"
                    :key="i"
                    class="pairSingleTicketWrapper"
                  >
                    <p style="font-weight: bold;">Single Ticket {{ i + 1 }}</p>
                    <v-radio-group
                      @change="addSeatingPreference"
                      row
                      :rules="seatingPreferenceRules"
                    >
                      <v-radio label="Q1" :value="`singleTicket${i}-Q1`">
                      </v-radio>
                      <v-radio label="Q2" :value="`singleTicket${i}-Q2`">
                      </v-radio>
                      <v-radio label="Z1" :value="`singleTicket${i}-Z1`">
                      </v-radio>
                      <v-radio label="Z2" :value="`singleTicket${i}-Z2`">
                      </v-radio>
                    </v-radio-group>
                  </v-card>
                </div>
              </div>
            </v-card>

            <v-btn
              color="primary"
              @click="getCurrentTicketCount(ticketProps.step)"
            >
              Continue
            </v-btn>

            <v-btn @click="prevStep" text>
              Back
            </v-btn>
          </v-form>
        </v-stepper-content>

        <!-- -------------------Step 4 ---------------------------->
        <v-stepper-content step="4">
          <v-form
            v-model="paymentInfoValid"
            ref="paymentFormValidation"
            @input="validatePaymentForm"
            lazy-validation
          >
            <p class="checkoutHeader">
              Please provide your name, email and phone for the reservation
              <span style="color: red;">(required)</span>:
            </p>
            <v-text-field
              class="ticketNameEmailField"
              v-model="buyerName"
              solo
              label="Full Name (First & Last)"
              required
              :rules="fullNameRules"
            >
            </v-text-field>
            <v-text-field
              class="ticketNameEmailField"
              v-model="buyerEmail"
              solo
              label="Email"
              required
              :rules="emailRules"
            >
            </v-text-field>
            <v-text-field
              class="ticketNameEmailField"
              v-model="buyerPhone"
              solo
              label="Phone"
              required
              :rules="phoneRules"
            >
            </v-text-field>
            <p class="checkoutHeader">
              Please review your ticket details and provide a payment method:
            </p>
            <!-- <p>Ticket Price Total: ${{ticketProps.entreePriceTotal}}</p> -->
            <div class="ledgerWrapper">
              <p>
                Total Number of Tickets:
                {{ ticketProps.singleTickets + ticketProps.pairTickets }}
              </p>
              <p v-if="ticketProps.singleTickets">
                ({{ ticketProps.singleTickets }} Single)
              </p>
              <p v-if="ticketProps.pairTickets">
                ({{ ticketProps.pairTickets }} Pair)
              </p>
              <p v-if="ticketProps.entreePriceTotal">
                ${{ ticketProps.entreePriceTotal }}
              </p>
            </div>
            <div v-if="ticketProps.addOnsTotal" class="ledgerWrapper">
              <p>Add Ons Total:</p>
              <p>${{ ticketProps.addOnsTotal }}</p>
            </div>
            <div class="divider"></div>

            <div class="totalCheckoutPrice">
              <p class="totalPriceText" v-if="ticketProps.totalTicketPrice > 0">
                Total Price:
              </p>
              <p>${{ ticketProps.totalTicketPrice }}</p>
            </div>
            <p class="checkoutHeader">Card Details</p>
            <div class="stripeWrapper">
              <form id="payment-form" @submit.prevent="handlePaymentForm">
                <div class="stripeCard" ref="card"></div>
                <div style="color: red;" id="card-errors"></div>
                <div style="color: red;" id="payment-errors"></div>
                <button class="payButton" type="submit" :disabled="lockSubmit">
                  Pay
                </button>
              </form>
            </div>
            <div>
              <v-btn @click="prevStep" text>
                Back
              </v-btn>
            </div>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import { loadStripe } from "@stripe/stripe-js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  name: "Tickets",
  props: ["ticketProps"],

  data: () => ({
    loadStripe,
    stripe: null,
    //DEV client side key
    // clientSideStripeAPIkey:
    //   "pk_test_51Jh8LAKu67xVf9q1khz6nK7audVYxEy2vnlz9hBMjGz5kWLMnIw78ROep8KAYM99wzBdRPgh26CUTY7LEM9iyu3V00SkPBmWjm",

    //PROD client side key
    clientSideStripeAPIkey: "pk_live_ABNYM6YA6G142Qi8ARq4EKgS00Rtx3cIeG",
    //PROD API Gateway endpoint
    lambdaStripe:
      "https://khadu3qoy3.execute-api.us-east-1.amazonaws.com/Stage/stripe",
    //DEV API Gateway endpoint StripeHandler-Dev
    // lambdaStripe:
    //   "https://oe5d0zq679.execute-api.us-east-1.amazonaws.com/Stage/strapi",
    classId: "",
    classUrl: "",
    error: null,
    stepOneValid: false,
    stepTwoValid: false,
    stepThreeValid: false,
    paymentInfoValid: false,
    lockSubmit: true,
    card: null,
    customers: {},
    partySizeRules: [
      (v) => v > 0 || "Please enter how many people are in your party",
    ],
    firstNameRules: [(v) => !!v || "First name is required"],
    lastNameRules: [(v) => !!v || "Last name is required"],
    fullNameRules: [(v) => !!v || "Name is required"],
    phoneRules: [(v) => (v || "").length === 10 || "Phone must be valid"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    entreeSelectedRules: [(v) => !!v || "Please select an entree"],
    seatingPreferenceRules: [
      (v) => !!v || "Please select a seating preference",
    ],
    partySize: null,
    maxPartySize: 24,
    singleTickets: null,
    entreeSelected: null,
    addOnSelected: [],
    addOnSelectedIndex: [],
    pairTicketSeats: [],
    singleTicketSeats: [],
    paymentIsLoading: false,
    fullPage: true,
    currentTicketCount: null,
    entreeIdDefault: 1,
    buyerName: "",
    buyerEmail: "",
    buyerPhone: "",
  }),
  components: {
    Loading,
  },
  computed: {
    ticketFormValid() {
      return this.customers;
    },
  },
  methods: {
    validatePaymentForm() {
      if (this.$refs.paymentFormValidation.validate() === true) {
        this.paymentInfoValid = true;
      }
    },
    getCurrentTicketCount(step) {
      this.$emit("getCurrentTicketCount", {
        partySize: this.partySize,
        step: step,
      });
    },

    validateStep(step) {
      if (step === 1 && this.$refs.stepOneForm.validate() === true) {
        this.$emit("nextStep");
      } else if (step === 2 && this.$refs.stepTwoForm.validate() === true) {
        this.$emit("updateEntrees", this.entreeSelected);
        this.$emit("updateTotalTicketPrice");
        this.$emit("nextStep");
      } else if (step === 3 && this.$refs.stepThreeForm.validate() === true) {
        this.$emit("nextStep");
      }
      // else if (step === 4 && this.$refs.paymentFormValidation.validate() === true) {
      //   console.log("payment for valid")
      // }
    },
    handlePaymentForm(event) {
      event.preventDefault();
      //confirm validation before proceeding
      if (this.paymentInfoValid === true) {
        this.paymentIsLoading = true;
        this.lockSubmit = true;
        const paymentError = document.getElementById("payment-errors");

        //format seatingPreference for payload
        let formattedSeatingPreference = JSON.stringify(
          this.ticketProps.seatingPreference
        );
        //check ticket count before proceeding with payment
        this.getCurrentTicketCount(this.ticketProps.step);
        //confirm name, email, and phone have been entered for payment, if validated, proceedd with payment
        // if (this.ticketProps.availableTickets >= this.partySize) {
        this.stripe.createToken(this.card).then(async (result) => {
          //dummy credit card info
          //cardNumber: 4242 4242 4242 4242
          //cardExpir: 09/22
          //cardCvC: 314
          //zip: 12345
          if (result.error) {
            // alert(result.error.message);
            paymentError.textContent =
              "There was an error with checkout, please ensure your card details are correct and try again.";
            this.paymentIsLoading = false;
            this.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
            return;
          } else {
            //result.token.id is the transactionToken that grabs the info entered into the stripe card element

            const intent = await fetch(this.lambdaStripe, {
              method: "POST",
              body: JSON.stringify({
                transactionToken: result.token.id,
                classId: this.$route.params.id,
                classUrl: "https://strapi.nyk.mindex.cloud/cooking-classes/",
                numberOfTickets: this.ticketProps.totalTicketCount,
                addOns: this.ticketProps.addOnsTotal,
                addOnsIndex: this.addOnSelectedIndex,
                classCategory: this.ticketProps.classCategory,
                entreeOneTickets: this.ticketProps.entreeOneTicketCount,
                entreeTwoTickets: this.ticketProps.entreeTwoTicketCount,
                email: this.buyerEmail,
                phone: this.buyerPhone,
                buyerName: this.buyerName,
                seatingPreference: formattedSeatingPreference,
              }),
            });
            const { paymentIntent } = await intent.json();
            await this.stripe
              .confirmCardPayment(paymentIntent.client_secret, {
                payment_method: {
                  card: this.card,
                  billing_details: {
                    //this would be the inputs from the Ticket form at step 4 name, email,phone
                    email: this.buyerEmail,
                    phone: this.buyerPhone,
                    name: this.buyerName,
                  },
                },
              })
              .then(async (result) => {
                if (result.error) {
                  this.paymentIsLoading = false;
                  paymentError.textContent = result.error.message;
                  //if fails
                  //GET to Insert-Email-Dev lambda
                  axios
                    .get(
                      `https://khadu3qoy3.execute-api.us-east-1.amazonaws.com/Stage/insertemail?succeded=false&orderid=${paymentIntent.id}`
                      // `https://oe5d0zq679.execute-api.us-east-1.amazonaws.com/Stage/insertemail?succeded=false&orderid=${paymentIntent.id}`
                    )
                    .then(() => {
                      this.paymentIsLoading = false;
                    })
                    .catch((err) => console.log(err.type, err.code));
                } else {
                  axios
                    .get(
                      `https://khadu3qoy3.execute-api.us-east-1.amazonaws.com/Stage/insertemail?succeded=true&orderid=${paymentIntent.id}`
                      // `https://oe5d0zq679.execute-api.us-east-1.amazonaws.com/Stage/insertemail?succeded=true&orderid=${paymentIntent.id}`
                    )
                    .then(() => {
                      this.$emit(
                        "updateTicketCount",
                        this.ticketProps.totalTicketCount
                      );
                      this.paymentIsLoading = false;
                    })
                    .catch((err) => console.log(err));
                }
              });
          }
        });
        // } else {
        //   this.paymentIsLoading = false;
        //   console.log("There was an error with checkout");
        // }
      }
    },

    prevStep() {
      this.$emit("prevStep");
    },
    prevStepReset() {
      this.$refs.stepTwoForm.reset();
      this.$emit("prevStep");
    },
    increasePartySize() {
      if (this.ticketProps.availableTickets !== 0) {
        this.partySize++;
      }
      this.$emit("increasePartySize", this.partySize);
    },
    decreasePartySize() {
      this.partySize--;
      this.$emit("decreasePartySize", this.partySize);
    },
    errorListener() {
      this.card.on("change", (event) => {
        const displayError = document.getElementById("card-errors");
        const paymentError = document.getElementById("payment-errors");
        if (event.complete) {
          this.lockSubmit = false;
          displayError.textContent = "";
        } else if (event.error) {
          this.lockSubmit = true;
          displayError.textContent = event.error.message;
          // alert(event.error);
        } else {
          displayError.textContent = "";
          paymentError.textContent = "";
        }
      });
    },
    updateAddOns() {
      let addOnSplit = this.addOnSelected.map((addOn) => addOn.split("-")[0]);
      this.addOnSelectedIndex = this.addOnSelected.map(
        (addOn) => addOn.split("-")[1]
      );
      let formatedAddOn = addOnSplit.map((addOn) =>
        Number(addOn)
          .toFixed(2)
          .toString()
      );
      this.$emit("updateAddOns", formatedAddOn);
    },
    updateEntrees(entreeId) {
      if (entreeId !== null) {
        let tempSplit = entreeId.split("-");
        this.entreeSelected = entreeId;
        this.$emit("updateEntrees", {
          ticketId: tempSplit[0],
          entreeId: tempSplit[1],
        });
      }
    },
    addSeatingPreference(seatingPreference) {
      let tempSplit = seatingPreference.split("-");
      this.$emit("updateSeatingPreference", {
        seatName: tempSplit[0],
        seatPreference: tempSplit[1],
      });
    },
  },
  async mounted() {
    this.paymentInfoValid = false;

    try {
      this.stripe = await loadStripe(this.clientSideStripeAPIkey);
      this.card = this.stripe.elements().create("card");
      this.card.mount(this.$refs.card);
      this.errorListener();
    } catch (error) {
      console.log(error);

      throw error;
    }
  },
};
</script>

<style scoped>
.ticketForm {
  width: 70%;
}
.ticketFormWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.ticketWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 800px;
  margin: auto;
}

.stepOneCard {
  width: 100%;
  padding: 20px;
  margin: 15px;
}
.partySizeTextField {
  width: 150px;
  margin: 15px 0 15px 0;
}

.partySizeWrapper {
  /* display: flex;
  justify-content: flex-start; */
}

.ticketTextWrapper {
  /* display: flex; */
}

.pairSingleTicketWrapper {
  margin: 10px 0 10px 0;
  padding: 10px;
}
.entreeWrapper {
  display: flex;
}
.entreeDetails {
  display: flex;
  margin: 10px;
}
.entreeNamePrice {
  margin: 10px;
}
.ticketTypeHeader {
  font-weight: bold;
}
.ticketStepHeader {
  text-align: center;
  margin: 10px 0px 40px 0px;
}
.ticketNameEmailWrapper {
  display: flex;
  flex-direction: column;
}
.ticketNameEmailWrapperPair {
  display: flex;
  flex-direction: column;
}
.ticketNameEmailField {
  width: 50%;
  min-height: 60px;
}
.ticketNameEmailFieldPair {
  width: 50%;
  min-height: 60px;
}
.totalCheckoutPrice {
  display: flex;
  justify-content: space-between;
  background-color: #b6f9b6;
  padding: 10px 6px 10px 6px;
  width: 50%;
  border-radius: 16px;
  margin: 0 0 20px 0;
  height: 45px;
}
.totalPriceText {
  font-weight: bold;
}
.stripeWrapper {
  width: 50%;
  height: 200px;
  border-radius: 16px;
  margin: 10px 0 10px 0;
  height: 100px;
}
.stripeCard {
  margin: 20px 0 20px 0;
}
.payButton {
  background-color: lightgreen;
  width: 200px;
  height: 30px;
  border-radius: 16px;
}
.checkoutHeader {
  font-weight: bold;
}
.ledgerWrapper {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.divider {
  width: 50%;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.seatingPreferenceText {
  font-weight: bold;
  font-style: italic;
  margin-right: 2px;
}
@media only screen and (max-width: 600px) {
  .ticketNameEmailField {
    width: 95%;
  }
  .entreeDetails {
    margin: 10px 0 10px 0;
  }
  .totalCheckoutPrice {
    display: flex;
    width: 100%;
    margin: 0 0 20px 0;
  }
  .stripeWrapper {
    width: 90%;
  }
  .ledgerWrapper {
    width: 100%;
  }
  .divider {
    width: 100%;
  }
}
</style>
