<template>
  <v-container class="eventsPageRoot">
    <p class="text-h2 text-center font-weight-bold">All Events</p>
    <Events v-bind:events="events" />
  </v-container>
</template>

<script>
import Events from './Events';
import axios from 'axios';

export default {
  name: 'EventsPage',
  data: () => ({
    events: [],
    error: null
  }),
  async mounted() {
    try {
      const response = await axios.get(
        'https://strapi.nyk.mindex.cloud/events'
      );
      this.events = response.data.sort((a, b) => {
        let dateA = new Date(a.startTime);
        let dateB = new Date(b.startTime);
        return dateA - dateB;
      });
      console.log(response.data);
    } catch (error) {
      this.error = error;
    }
  },
  components: {
    Events
  }
};
</script>

<style scoped>
.eventsPageRoot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
