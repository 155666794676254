<template>
  <div>
    <v-app-bar color="black" fixed>
      <div style="position: absolute;">
        <v-app-bar-nav-icon color="white" @click="drawer = true">
        </v-app-bar-nav-icon>
      </div>
      <!-- <v-spacer></v-spacer> -->
      <div class="logo_container">
        <router-link to="/" tag="span" class="logo_span">
          <img
            src="../assets/nyk-header-logo-transparent.svg"
            class="navLogo"
          />
        </router-link>
      </div>
      <!-- <v-spacer></v-spacer> -->
      <div class="socialIconsWrapper">
        <v-btn
          v-for="icon in icons"
          :key="icon.iconName"
          class="white--text"
          icon
          :href="icon.link"
          target="_blank"
        >
          <v-icon size="24px" color="white">
            {{ icon.iconName }}
          </v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      height="100%"
      style="position: fixed; top: 0; left: 0"
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="gray--text text--accent-4"
        >
          <router-link to="/about" class="hambugerMenuLink">
            <v-list-item>
                <v-list-item-title>About Us</v-list-item-title>
            </v-list-item>
          </router-link>
      
          <router-link to="/classes" class="hambugerMenuLink">
            <v-list-item>
                <v-list-item-title>Classes</v-list-item-title>
            </v-list-item>
          </router-link>



          <router-link to="/juniorchef" class="hambugerMenuLink">
            <v-list-item>
                <v-list-item-title>Junior Chef Initiative</v-list-item-title>
            </v-list-item>
          </router-link>


          <router-link to="/tastingroom" class="hambugerMenuLink">
            <v-list-item>
                <v-list-item-title>Tasting Room</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/terrace" class="hambugerMenuLink">
            <v-list-item>
                <v-list-item-title>Terrace</v-list-item-title>
            </v-list-item>
          </router-link>
        

          <router-link to="/faqs" class="hambugerMenuLink">
            <v-list-item>
                <v-list-item-title>FAQs</v-list-item-title>
            </v-list-item>
          </router-link>
      

          <router-link to="/blogs" class="hambugerMenuLink">
            <v-list-item>
                <v-list-item-title>Blogs</v-list-item-title>
            </v-list-item>
          </router-link>


          <router-link to="/privateevents" class="hambugerMenuLink">

            <v-list-item>
                <v-list-item-title>Private Events</v-list-item-title>
            </v-list-item>
          </router-link>


          <router-link to="/restaurant" class="hambugerMenuLink">
            <v-list-item>
                <v-list-item-title>The Restaurant</v-list-item-title>
            </v-list-item>
          </router-link>


          <!-- <v-list-item>
            <router-link to="/events" class="hambugerMenuLink">
              <v-list-item-title>Special Events</v-list-item-title>
            </router-link>
          </v-list-item>
          
           -->
          <router-link to="/partners" class="hambugerMenuLink">
            <v-list-item>
                <v-list-item-title>Partners</v-list-item-title>
            </v-list-item>
          </router-link>



        </v-list-item-group>
      </v-list>
      <div class="socialIconsWrapperNav">
        <v-btn
          v-for="icon in icons"
          :key="icon.iconName"
          class="black--text"
          icon
          :href="icon.link"
          target="_blank"
        >
          <v-icon size="24px" color="black">
            {{ icon.iconName }}
          </v-icon>
        </v-btn>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "Nav",

  data: () => ({
    drawer: false,
    group: null,

    icons: [
      {
        iconName: "mdi-facebook",
        link: "https://www.facebook.com/NYKitchenCanandaigua/",
      },
      {
        iconName: "mdi-twitter",
        link: "https://twitter.com/_nykitchen?lang=en",
      },
      {
        iconName: "mdi-linkedin",
        link: "https://www.linkedin.com/company/ny-kitchen",
      },
      {
        iconName: "mdi-instagram",
        link: "https://www.instagram.com/_nykitchen/?hl=en",
      },
    ],
  }),
};
</script>

<style scoped>
.hambugerMenuLink {
  text-decoration: none;
  color: black;
}

.logo_span {
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.navLogo {
  width: 200px;
}
.socialIconsWrapper {
  display: block;
  position: absolute;
  right: 2rem;
}

.socialIconsWrapperNav {
  display: none;
}

@media only screen and (max-width: 700px) {
  .socialIconsWrapper {
    display: none;
  }
  .socialIconsWrapperNav {
    display: flex;
    margin: 10px;
  }
}
</style>
