<template>
  <div>
    <v-sheet height="64">
      <v-toolbar flat>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right> </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="875">
      <v-calendar
        v-if="calendartype == 1"
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="classes"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-more="false"
        @click:event="redirectEvent"
      ></v-calendar>
      <v-calendar
        v-if="calendartype == 2"
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-more="false"
        @click:event="redirectEvent"
      ></v-calendar>
      <v-calendar
        v-if="calendartype == 3"
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="combined"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-more="false"
        @click:event="redirectEvent"
      ></v-calendar>
    </v-sheet> 
  </div>
</template>
<script>
import axios from "axios";
import formatISO from "date-fns/formatISO";

export default {
  name: "Calendar",
  props: ["calendartype"],
  data: () => ({
    formatISO,
    type: "month",
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: "",
    classes: [],
    events: [],
    combined: [],
  }),
  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    redirectEvent({ event }) {
      window.open(event.redirect, "_blank");
    },
    async fetchClasses() {
      const url = "https://strapi.nyk.mindex.cloud/cooking-classes?_limit=-1";
      const response = await axios.get(url);
      const filteredClassList = await response.data;
      for (let i = 0; i < filteredClassList.length; i++) {
        const classes = {
          name: filteredClassList[i].name,
          start: new Date(filteredClassList[i].startTime),
          end: new Date(filteredClassList[i].endTime),
          color: "black",
          redirect: "classesdetails/" + filteredClassList[i].id,
        };
        this.classes.push(classes);
        this.combined.push(classes);
      }
    },
    async fetchGeneralClasses() {
      const url = "https://strapi.nyk.mindex.cloud/general-classes?_limit=-1";
      const response = await axios.get(url);
      const filteredGeneralClasses = await response.data;
      for (let i = 0; i < filteredGeneralClasses.length; i++) {
        const event = {
          name: filteredGeneralClasses[i].name,
          start: new Date(filteredGeneralClasses[i].startTime),
          end: new Date(filteredGeneralClasses[i].endTime),
          color: "black",
          redirect: "generalclassesdetails/" + filteredGeneralClasses[i].id,
        };
        this.events.push(event);
        this.combined.push(event);
      }
    },
    async fetchEvents() {
      const url = "https://strapi.nyk.mindex.cloud/events?_limit=-1";
      const response = await axios.get(url);
      const filteredEventsList = await response.data;
      for (let i = 0; i < filteredEventsList.length; i++) {
        const event = {
          name: filteredEventsList[i].name,
          start: new Date(filteredEventsList[i].startTime),
          end: new Date(filteredEventsList[i].endTime),
          color: "grey",
          redirect: "eventsdetails/" + filteredEventsList[i].id,
        };
        this.events.push(event);
        this.combined.push(event);
      }
    },
  },
  mounted() {
    this.$refs.calendar.move(0);
    this.fetchClasses();
    this.fetchGeneralClasses();
    this.fetchEvents();
  },
};
</script>
