import { format, utcToZonedTime } from "date-fns-tz";
/**
 * Creates a formatted string to describe the time a class is
 * @param {start date of class - Date string} startDate
 * @param {end date of class - Date string} endDate
 */
export const createHumanReadableDateString = (
  startDateString,
  endDateString
) => {
  const locale = "en-US";
  const timezone = "America/New_York";
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  const dateFormat = "iii, LLL dd yyyy";
  const timeFormat = "h:mm aaa";
  const dateAndTimeFormat = "iii, LLL dd yyyy h:mm aaa";

  const toRocTime = (time) => {
    return utcToZonedTime(time, timezone);
  };

  const toRocDateString = (time) => {
    const options = {
      timeZone: timezone,
    };
    return time.toLocaleDateString(locale, options);
  };

  return toRocDateString(startDate) === toRocDateString(endDate)
    ? `${format(toRocTime(startDate), dateFormat)}, ${format(
        toRocTime(startDate),
        timeFormat
      )}-${format(toRocTime(endDate), timeFormat)}`
    : `${format(toRocTime(startDate), dateAndTimeFormat)}-${format(
        toRocTime(endDate),
        dateAndTimeFormat
      )}`;
};
