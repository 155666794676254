<template>
  <v-container>
    <p class="text-h2 text-center font-weight-bold">Junior Chef Initiative</p>

    <v-container class="d-md-flex-column justify-center align-center">
      <v-card
        class="d-md-flex justify-center align-center cardWrapper"
        elevation="2"
      >
        <v-img
          class="cardImg"
          src="https://nyk-frontend.s3.amazonaws.com/Junior+Chef+2021-Wegmans-6.jpg"
        ></v-img>
        <div>
          <v-card-title>Inspiring the Next Generation of Chefs</v-card-title>
          <v-card-text>
            At the heart of New York Kitchen’s educational mission is the Junior
            Chef Initiative. These summer camps and year-round workshops empower
            today’s youth through local food and fun in the kitchen. Through
            partnerships with area after school programs that provide a pathway
            for kids and young adults to reach their full potential, New York
            Kitchen offers hands-on cooking camps and workshops to participating
            students, thanks to our generous donors and dedicated supporters.
          </v-card-text>
          <v-card-text>
            In addition to cooking, New York Kitchen’s Junior Chef community
            camps include agricultural education and on-site farm experiences,
            farmers market shopping, an introduction to the restaurant and
            hospitality industries, continuing education opportunities, and so
            much more. New York Kitchen’s Junior Chef Initiative teaches key
            life skills including the importance of communication, time
            management, problem solving, work ethic and economic
            self-sufficiency, while building up self-esteem and self-confidence
            for participating youth.
          </v-card-text>
          <v-card-text>
            Junior Chef community partners include the Boys & Girls Club of
            Rochester, Boys & Girls Club of Geneva, Education Success
            Foundation: Future Ready, Wegmans Work Scholarship Program, Champion
            Academy, Youth Advocate Programs of Monroe and Ontario counties, and
            more!
          </v-card-text>
          <!-- <v-card-text>
            Our Junior Chef Initiative works in partnership with area after
            school programs to educate, engage, and excite budding junnior
            chefs. They will also expand their culinary skill set by learning
            about different ingredients and cooking methods. So, move over mom
            and dad because after this camp your aspiring chef will be making
            dinner!
          </v-card-text>
          <v-card-text>
            We offer an array of different camps, each focusing on different key
            skills to help them along the road to becoming a world class juinor
            chef. Topics that are covered include things such as knife skills
            and safety, cooking methods, and recipe building.
          </v-card-text> -->
        </div>
      </v-card>
    </v-container>
    <!-- <v-container>
      <p class="text-h4 classesHeader">Junior Chef Classes</p>
      <div class="viewAllClassesWrapper">
        <v-btn
          href="/classes"
          color="black lighten-2"
          class="white--text"
          height="50px"
          to=""
          >View All Classes
        </v-btn>
      </div>
      <Classes v-bind:cookingClasses="cookingClasses" />
    </v-container> -->
    <v-container class="d-md-flex-column justify-center align-center">
      <v-card
        class="d-md-flex justify-center align-center cardWrapper"
        elevation="2"
      >
        <div class="donateText">
          <p class="text-h2 text-center font-weight-bold">Donate</p>
          <v-card-text>
            Support New York Kitchen’s Junior Chef Initiative with a
            tax-deductible gift today.
          </v-card-text>
          <v-card-text>
            Have questions about ways to give, major gifts, or in-kind support?
            <span><a href="mailto:alyssa.belasco@nykitchen.com">Contact us</a></span> today!
          </v-card-text>
          <div class="donateChefWrapper">
            <v-btn
              color="#175274"
              class="white--text"
              height="50px"
              href="http://weblink.donorperfect.com/nykitchen"
              target="_blank"
            >
              GIVE TODAY
            </v-btn>
          </div>
        </div>
        <v-img
          contain="true"
          class="cardImg"
          src="https://nyk-frontend.s3.amazonaws.com/Junior+Chef+2020-Champion-1.jpg"
        ></v-img>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
// import Classes from "./Classes";
// import axios from "axios";

export default {
  name: "JuniorChef",
  data: () => ({
    cookingClasses: [],
    error: null,
  }),
  // async mounted() {
  //   try {
  //     //check the category id in the Strapi admin, junior-chef's id = 1
  //     const response = await axios.get(
  //       "https://strapi.nyk.mindex.cloud/categories/1"
  //     );
  //     this.cookingClasses = response.data.cooking_classes;
  //   } catch (error) {
  //     this.error = error;
  //   }
  // },
  // components: {
  //   Classes,
  // },
};
</script>

<style scoped>
.juniorChefHeaderText {
  text-align: center;
  margin: 20px;
}
.craftWrapper {
  margin: 20px 0 20px 0;
}
.cardImg {
  height: 500px;
  width: 50%;
}
.donateText {
  margin-left: 5%;
}
@media only screen and (max-width: 960px) {
  .cardImg {
    width: 100%;
  }
  .donateText {
  margin-left: unset;
  }
  .donateText div {
    text-align: center;
  }
}
.classesHeader {
  text-align: center;
  margin: 20px;
}
.donateChefWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.viewAllClassesWrapper {
  display: flex;
  justify-content: center;
}

.viewAllClassesWrapper a {
  text-transform: none;
}
</style>
