<template>
  <div class="text-center">
    <v-container fluid pa-0>
      <v-dialog v-model="dialog" width="700">
        <v-card>
          <div class="newsletterWrapper">
            <div class="newsletterFormWrapper">
              <p class="text-h4 text-center">Join our Newsletter!</p>
              <p
                class="text-h5 font-weight-bold text-center newsletterModalSubHeader"
              >
                Get the latest New York Kitchen news, special offers, classes,
                and events delivered straight to your inbox.
              </p>
              <form
                action="https://nykitchen.us19.list-manage.com/subscribe/post?u=29d0c4ed66f868368b6f850cb&amp;id=4d210b2b56"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                class="validate newsletterForm"
                novalidate
              >
                <div id="mc_embed_signup_scroll">
                  <div class="mc-field-group">
                    <v-text-field
                      v-model="firstName"
                      :rules="firstNameRules"
                      name="FNAME"
                      id="mce-FNAME"
                      label="First Name"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="lastName"
                      :rules="lastNameRules"
                      id="mce-LNAME"
                      name="LNAME"
                      label="Last Name"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      id="mce-EMAIL"
                      name="EMAIL"
                      class="required email"
                      required
                    ></v-text-field>
                  </div>
                  <div id="mce-responses" class="clear">
                    <div
                      class="response"
                      id="mce-error-response"
                      style="display:none"
                    ></div>
                    <div
                      class="response"
                      id="mce-success-response"
                      style="display:none"
                    ></div>
                  </div>
                  <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                  <div
                    style="position: absolute; left: -5000px;"
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_29d0c4ed66f868368b6f850cb_4d210b2b56"
                      tabindex="-1"
                      value=""
                    />
                  </div>
                  <v-btn
                    block
                    :disabled="!valid"
                    color="black lighten-2"
                    class="white--text my-4"
                    large
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    type="submit"
                  >
                    GET THE NEWSLETTER
                  </v-btn>
                </div>
              </form>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "NewsletterModal",
  props: ["dialog"],
  data: () => ({
    error: null,
    valid: true,
    firstName: "",
    lastName: "",
    firstNameRules: [v => !!v || "First name is required"],
    lastNameRules: [v => !!v || "Last name is required"],
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ]
  })
};
</script>

<style scoped>
.newsletterModalSubHeader {
  max-width: 600px;
}
.newsletterModalButton {
  width: 100%;
}
.newsletterForm {
  width: 100%;
}
.newsletterFormWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem;
}

.newsletterWrapper {
  display: flex;
  justify-content: center;
  margin: auto;
}
</style>
