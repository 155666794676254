<template>
  <v-container fluid pa-0>
    <v-container>
      <p class="text-h2 text-center font-weight-bold">Private Events</p>
      <v-card
        class="d-md-flex justify-center align-center firstPrivateCard"
        elevation="2"
      >
        <v-img class="cardImg" src="../assets/events_outdoor.jpg"></v-img>
        <div>
          <v-card-title>The Perfect Location for Your Celebration</v-card-title>
          <v-card-text>
            Located on the north end of beautiful Canandaigua Lake, we’re the
            perfect destination for your private event or gathering. With
            awe-inspiring views, and a breathtaking atmosphere, this will be an
            event your guests won’t likely forget. Our team of hospitality
            experts will work with you every step of the way to find the perfect
            location for your celebration. Each of our event spaces are unique
            in their own way, and can be tailored to fit the setting and
            atmosphere you’re looking for.
          </v-card-text>
          <v-card-text>
            Our chefs work directly with local area farmers to provide top
            notch, farm-to-table fare with a New York State twist, sure to
            provide an unforgettable dining experience. Our beverage curators
            will “craft up” the perfect New York State beverages with care, to
            ensure you and your guests have a true New York State tasting
            experience.
          </v-card-text>
          <v-card-text>
            In all of our weddings, events, meetings, and private celebrations,
            we pride ourselves in showcasing the best of what New York has to
            offer, and we can’t wait to share it with you! Ready to celebrate?
            Let’s start planning!
          </v-card-text>
        </div>
      </v-card>
    </v-container>
    <v-container>
      <div class="privatePhotoWrapper">
          <v-img class="privateEventPhoto" src="../assets/private_event_dancing.jpg"></v-img>
          <v-img class="privateEventPhoto" src="../assets/private_event_green.jpg"></v-img>
          <v-img class="privateEventPhoto" src="../assets/private_event_plate1.jpg"></v-img>
          <v-img class="privateEventPhoto" src="../assets/private_event_table.jpg"></v-img>
          <v-img class="privateEventPhoto" src="../assets/private_event_wedding.jpg"></v-img>
          <v-img class="privateEventPhoto" src="../assets/private_event_green2.jpg"></v-img>
          <v-img class="privateEventPhoto" src="../assets/private_event_plate2.jpg"></v-img>
          <v-img class="privateEventPhoto" src="../assets/private_event_wedding2.jpg"></v-img>
          <v-img class="privateEventPhoto" src="../assets/private_event_plate3.jpg"></v-img>
      </div>
    </v-container>
    <v-container>
      <p class="text-h2 text-center font-weight-bold">Our Venues</p>
       <v-card
        class="d-md-flex justify-center align-center privateEventsCard"
        elevation="2"
      >
        <v-img class="privateEventCardPhoto" src="../assets/venues_sands_gallery.jpg"></v-img>
        <div>
          <v-card-title>Sands Gallery</v-card-title>
          <p class="privateEventCardSubtitle">Overlooking both Canandaigua Lake and the center’s gardens</p>
          <v-card-text>
           Overlooking both Canandaigua Lake and the Center’s gardens, the Sands Gallery is perfect for private events, workshops, seminars, and business meetings. Offering a rustic elegance, cozy fireplace, and beautiful views of Canandaigua Lake, this space offers a memorable experience for special occasions and family celebrations alike.
          </v-card-text>
        </div>
      </v-card>
       <v-card
        class="d-md-flex justify-center align-center privateEventsCard"
        elevation="2"
      >
        <div>
          <v-card-title>Garden Tent</v-card-title>
          <p class="privateEventCardSubtitle">Surrounding the southern and western sides of the center</p>
          <v-card-text>
An array of New York agriculture is on display for all to enjoy in the beautiful New York Garden, which surrounds the southern and western sides of the Center. Depending on the time of year you stroll through the garden, you may discover grapes ready for picking, bouquets of apple blossoms, fragrant herbs, or the lush colors of ripening tomatoes, corn, beans, or squash. Our freshest ingredients are grown right in our backyard! The New York Garden is an incredible space to host any of your private events, in a breathtaking, outdoor atmosphere.          </v-card-text>
        </div>
            <v-img class="privateEventCardPhoto" src="../assets/private_event_green.jpg"></v-img>
      </v-card>
      <v-card
        class="d-md-flex justify-center align-center privateEventsCard"
        elevation="2"
      >
        <v-img class="privateEventCardPhoto" src="../assets/venues_private_table.jpg"></v-img>
        <div>
          <v-card-title>Private Dining Room</v-card-title>
          <p class="privateEventCardSubtitle">Perfect for small gatherings</p>
          <v-card-text>
Whether you’re hosting your own wine tasting, having a small family dinner party, entertaining clients or gathering with friends, the private dining room is the perfect setting for your next celebration. Your friends, co-workers, or loved ones will be seated around a grand hardwood dining table, fit for royalty. The warm, rustic atmosphere of our Private Dining Room, with breathtaking views of Canandaigua Lake is perfect for an intimate event, or can be used in combination with any of our other event spaces.          </v-card-text>
        </div>
      </v-card>
      <v-card
        class="d-md-flex justify-center align-center privateEventsCard"
        elevation="2"
      >
        <div>
          <v-card-title>Educational Theatre</v-card-title>
          <p class="privateEventCardSubtitle">The perfect space for a memorable, interactive, culinary experience</p>
          <v-card-text>
In a comfortable amphitheater setting, enhanced by state-of-the-art audio and visual technology, you can watch chefs create dazzling dishes using superb New York State agricultural products. Better yet, you get to sample these delectable creations. The Educational Theater also hosts wine, craft beer, cider, and spirits tastings, in addition to beverage appreciation classes and other culinary courses. Don’t worry, no need to be an expert to participate, we offer a variety of courses that cater to any experience level. The perfect space for a memorable, interactive, culinary experience for your guests.          </v-card-text>
        </div>
            <v-img class="privateEventCardPhoto" src="../assets/venue_theatre.jpg"></v-img>
      </v-card>
      <v-card
        class="d-md-flex justify-center align-center privateEventsCard"
        elevation="2"
      >
        <v-img class="privateEventCardPhoto" src="../assets/venue_hands_on_kitchen.jpg"></v-img>
        <div>
          <v-card-title>Hands-On Kitchen</v-card-title>
          <p class="privateEventCardSubtitle">An all-encompassing, interactive experience</p>
          <v-card-text>
Whether you’re hosting your own wine tasting, having a small family dinner party, entertaining clients or gathering with friends, the private dining room is the perfect setting for your next celebration. Your friends, co-workers, or loved ones will be seated around a grand hardwood dining table, fit for royalty. The warm, rustic atmosphere of our Private Dining Room, with breathtaking views of Canandaigua Lake is perfect for an intimate event, or can be used in combination with any of our other event spaces.          </v-card-text>
        </div>
      </v-card>
    </v-container>
    <v-container fluid class="planningContainer">
      <div class="planningButtonWrapper">
        <v-btn
          color="black lighten-2"
          class="white--text planningButton"
          height="50px"
          width="300px"
          href="https://newyorkkitchen.tripleseat.com/party_request/14135"
        >
        Let's Start Planning

        </v-btn>
      </div>
    </v-container>
    <v-container fluid class="virtualTourplanningContainer">
      <div class="planningButtonWrapper">
        <v-btn
          color="black lighten-2"
          class="white--text planningButton"
          height="50px"
          width="300px"
          href="https://cloud.threshold360.com/locations/New-York-Kitchen-43b7c56b"
        >
        View Our Virtual Tour Here
        
        </v-btn>
      </div>
    </v-container>
    <!-- <v-container>
      <p class="text-h4 text-center font-weight-bold">The Food Truck</p>
      <v-card class="d-md-flex justify-center align-center" elevation="2">
        <div>
          <v-card-title>The Perfect Location for Your Celebration</v-card-title>
          <v-card-text>
            Located on the north end of beautiful Canandaigua Lake, we’re the
            perfect destination for your private event or gathering. With
            awe-inspiring views, and a breathtaking atmosphere, this will be an
            event your guests won’t likely forget. Our team of hospitality
            experts will work with you every step of the way to find the perfect
            location for your celebration. Each of our event spaces are unique
            in their own way, and can be tailored to fit the setting and
            atmosphere you’re looking for.
          </v-card-text>
          <v-card-text>
            Our chefs work directly with local area farmers to provide top
            notch, farm-to-table fare with a New York State twist, sure to
            provide an unforgettable dining experience. Our beverage curators
            will “craft up” the perfect New York State beverages with care, to
            ensure you and your guests have a true New York State tasting
            experience.
          </v-card-text>
          <v-card-text>
            In all of our weddings, events, meetings, and private celebrations,
            we pride ourselves in showcasing the best of what New York has to
            offer, and we can’t wait to share it with you! Ready to celebrate?
            Let’s start planning!
          </v-card-text>
        </div>
        <v-img class="cardImg" src="../assets/food_truck_3.jpg"></v-img>
      </v-card>
    </v-container> -->
     

        
  </v-container>
</template>

<script>
export default {
  name: "PrivateEvents",
};
</script>

<style scoped>
.cardImg {
  height: 500px;
  width: 50%;
}

.privateEventCardPhoto {
  height: 100%;
  width: 50%;
}

.firstPrivateCard {
  margin: 40px 0px 40px 0px;
}

.privateEventsCard {
  height: auto;
  margin: 40px 0px 40px 0px;
}

.privateEventPhoto{
    flex: 1 1 32%;
    margin: 5px;
    height: 340px;
}

.privatePhotoWrapper{
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
}

.privateEventCardSubtitle{
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 16px;
    margin-bottom: 0px;
}

.planningButton{
  border-radius: 16px;
}

.planningButtonWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.planningContainer {
  padding-right: 0;
  padding-left: 0;
  background-color:#e8e8e8;
  height: 300px;
}

.virtualTourplanningContainer {
  padding-right: 0;
  padding-left: 0;
  background-color:#ffffff;
  height: 300px;
}

@media only screen and (max-width: 960px) {
  .cardImg {
    width: 100%;
    margin-bottom: 20px !important;
  }
  .privateEventCardPhoto {
  max-height: 350px;
  width: 100%;
}
}
@media only screen and (max-width: 600px) {
  .cardImg {
    max-height: 240px;
  }
}
</style>
