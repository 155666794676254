<template>
  <v-container fluid class="pa-0">
    <div class="terraceContentWrapper">
      <div class="contentSubWrapperLeft">
        <p class="contentLeftHeader">
          Help us cultivate a new field of open-air exploration
        </p>
        <p>
          For fifteen years, The Tasting Room at New York Kitchen has broadened
          the Empire State sense-abilities of all who have visited here, from
          Main Street, to around the world. And we’re just getting started.
        </p>
        <p>
          We will soon extend both our space, and an invitation, to a brand new
          Tasting Room Terrace that promises many new seasons for you to savor.
          As a 501(c)(3) nonprofit organization, your support will make it
          possible for us to take the imbibe outside.
        </p>
        <p>
          With you in the mix, we can look forward to this expansion introducing
          fresh notes of invigorating air, more grounded learning, and new
          narratives that will invite bold characters.
        </p>
        <div class="leftButtonWrapper">
          <v-btn
            target="_blank"
            width="175px"
            href="https://nyk-frontend.s3.amazonaws.com/NYK_Terrace_Brochure_WEB.pdf"
            color="#175073"
            class="white--text readMoreButton"
            >Read More</v-btn
          >
          <v-btn color="#558442" class="white--text donateButton" width="175px" target="_blank" href="https://interland3.donorperfect.net/weblink/weblink.aspx?name=E254056&id=5"
            >Donate</v-btn
          >
        </div>
      </div>
      <div class="contentSubWrapperCenter">
        <v-img
          class="terraceLogo"
          src="https://nyk-frontend.s3.amazonaws.com/terrace/NYK-Terrace-Logo.png"
          contain="true"
        >
        </v-img>
        <div class="centerContentImageWrapper">
          <v-img
            max-width="150px"
            contain="true"
            src="https://nyk-frontend.s3.amazonaws.com/terrace/Pint.png"
          >
          </v-img>
          <v-img
            max-width="150px"
            contain="true"
            src="https://nyk-frontend.s3.amazonaws.com/terrace/Cheers.png"
          >
          </v-img>
        </div>
      </div>
      <div class="contentSubWrapperRight">
        <v-img
          class="yellowWallImage"
          contain="true"
          src="https://nyk-frontend.s3.amazonaws.com/terrace/Patio-Block-Yellow_STATS.png"
        >
        </v-img>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Terrace",
  data: () => {},
};
</script>

<style scoped>
.terraceContentWrapper {
  display: flex;
  justify-content: space-between;
  background-image: url("https://nyk-frontend.s3.amazonaws.com/terrace/branches.png");
  background-size: contain;
}
.contentSubWrapperCenter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
  justify-content: center;
}
.contentSubWrapperLeft {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
  padding: 50px;
}
.contentSubWrapperRight {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
  justify-content: center;
}
.contentLeftHeader {
  font-weight: bold;
  color: #135377;
}
.readMoreButton {
  border-radius: 16px;
  margin: 20px;
}
.donateButton {
  border-radius: 16px;
  margin: 20px;
}
.terraceLogo {
  width: 400px;
  margin: auto;
}
.centerContentImageWrapper {
  display: flex;
  justify-content: center;
}
.leftButtonWrapper {
  display: flex;
  justify-content: center;
}
  .yellowWallImage {
    max-width: 600px;
  }

@media only screen and (max-width: 1350px) {
  .terraceContentWrapper {
    flex-direction: column;
  }
  .contentSubWrapperRight {
    flex-direction: row;
  }

}
@media only screen and (max-width: 600px) {
  .leftButtonWrapper {
    flex-direction: column;
    margin: auto;
  }
    .yellowWallImage {
    max-width: 400px;
  }
}
</style>
