<template>
  <v-container>
    <div>
      <p class="text-h2 text-center font-weight-bold">Blogs</p>
      <Blogs v-bind:blogs="blogs" />
    </div>
  </v-container>
</template>

<script>
import Blogs from './Blogs';
import axios from 'axios';

export default {
  name: 'BlogsPage',
  data: () => ({
    blogs: [],
    error: null
  }),
  async mounted() {
    try {
      const response = await axios.get(
        'https://strapi.nyk.mindex.cloud/blogs/'
      );
      this.blogs = response.data.sort((a, b) => {
        let dateA = new Date(a.published_at);
        let dateB = new Date(b.published_at);
        return dateA - dateB;
      });
    } catch (error) {
      this.error = error;
    }
  },
  components: {
    Blogs
  }
};
</script>

<style scoped></style>
