<template>
  <div class="cancelDisclosureWrapper">
    <p><b>DISCLOSURES:</b></p>
    <ul>
      <li>
        There will be two people per station. For this reason, every two people in your party must prepare the same dish.
      </li>
      <li>
        All guests <b>must</b> wear closed toe shoes when attending a class in
        our Hands-On Kitchens.
      </li>
      <li>
        Guests must be 21 years or older to consume alcohol.
        <b>I.D. IS REQUIRED</b>.
      </li>
      <li>
        Recipes are developed using common ingredients which may or may not be
        specified in the title including flour, nuts, spices, fruits,
        vegetables, etc. If you have a food allergy, please call the Front Desk
        to discuss your options BEFORE registering for a class.
        <b>Adjustments to recipes cannot be made.</b>
      </li>
      <li>
        To ensure the safety of you and those around you, all guests are asked
        to give their full attention to the Chef Instructor as they cover
        important kitchen cooking skills and tips.
      </li>
      <li>
        NYK reserves the right to ask guests that are intoxicated, behaving in
        an unsafe manner or that pose a risk to leave the classroom.
      </li>
    </ul>
    <br />
    <p><b>CANCELLATION POLICY:</b></p>
    <ul>
      <li>
        Class ticket purchases are non-refundable or exchangeable for any
        reason. This is because New York Kitchen purchases materials and plans
        for classes based on the number of participants registered. However, if
        you’d like to offer your seat in that class to a friend or family
        member, we’d be happy to let them take your seat. Patrons should use the
        original purchaser’s name upon arrival at check-in.
      </li>
      <li>
        No Show Policy: New York Kitchen will not refund for no‐shows if a guest
        books a class reservation and attendees do not show.
      </li>
      <li>
        New York Kitchen reserves the right to cancel any class that fails to
        attract sufficient enrollment, or due to educator illness or inclement
        weather. We will contact you by phone or email and issue a full refund
        or you may request to be transferred to another class of your choice
        (depending on availability and value).
      </li>
    </ul>
    <div>
      <br />
      <p><b>PHOTO DISCLAIMER:</b></p>
      <ul>
        <li>
          Audio/Visual Release Policy and Acknowledgement: By registering and attending 
          a class, seminar, program, conference, session, or other event (collectively, an “Event”) 
          sponsored by New York Kitchen or any affiliated entity thereof (collectively, “NYK”), 
          you acknowledge and agree that NYK has the right at the Event to record, film, photograph, 
          or capture your voice and/or likeness in any media now available or hereafter developed 
          and to distribute, broadcast, use, or otherwise globally to disseminate, in perpetuity, 
          such media for any purpose whatsoever without any further approval from you or any payment to you. 
          You further acknowledge and agree that all such media and all rights therein are the sole property 
          of NYK. Without limiting the foregoing, the foregoing includes the right to edit such media, 
          the right to use the media alone or together with other information, and the right to license 
          others to use or disseminate the media.
        </li>
        </ul>
        </div>
  </div>
</template>

<script>
export default {
  name: "DisclosuresCancellations",
};
</script>

<style scoped>
.cancelDisclosureWrapper p {
  font-size: 0.875rem;
}
.cancelDisclosureWrapper li {
  font-size: 0.875rem;
}
</style>
