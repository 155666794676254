<template>
  <v-container>
    <v-card
      class="d-md-flex justify-center align-center cardWrapper"
      elevation="2"
    >
      <v-img class="cardImg" src="../assets/about_us_bar2.jpg"></v-img>
      <div>
        <p class="text-h2 text-center font-weight-bold">About Us</p>

        <v-card-text
          >Located in the heart of the Finger Lakes, New York Kitchen is a
          501(c)(3) nonprofit organization, originating from the collaboration
          of Constellation Brands, Wegmans Food Markets, Rochester Institute of
          Technology, and the New York Wine and Grape Foundation. These dynamic
          forces came together with a shared passion and mission to create a
          gateway for people around the world to experience New York’s
          incredible agriculture and viticulture industries.
        </v-card-text>
        <v-card-text>
          New York Kitchen believes that a strong connection between people,
          place, and produce is essential to promoting healthy lifestyles and
          thriving communities. We use the stories of agriculture, food, and
          beverage to inspire creative exploration to educate, excite, and
          engage the world in a celebration of the incredible bounty of New York
          State.
        </v-card-text>
        <v-card-text>
          Each day, New York Kitchen drives this mission forward through its
          educational hands-on cooking and craft beverage pairing classes and
          programs, industry certification courses, and 100% New York State
          Tasting Room. Learn more about our Junior Chef Initiative
          <span><router-link to="/juniorchef">here</router-link></span
          >.
        </v-card-text>
        <v-card-text>
          Through partnerships with area farmers, wineries, breweries,
          distilleries, producers, and entrepreneurs, we offer an array of
          inviting experiences which proudly highlight and promote the quality,
          diversity, and economic impact of food and beverages that originate in
          New York State.
        </v-card-text>
      </div>
    </v-card>
    <div class="teamRoot">
      <p class="text-h2 text-center font-weight-bold aboutHeader">Our Team</p>
      <div class="teamWrapper">
        <div v-for="employee in employees" :key="employee.id">
          <v-card class="my-12 teamCard">
            <v-card-title class="aboutName">{{ employee.Name }}</v-card-title>
            <div class="aboutTitleEmailWrapper">
              <v-card-text class="aboutTitle">
                {{ employee.Title }}
              </v-card-text>
              <v-card-text class="aboutEmail">
                <a :href="'mailto:' + employee.email">
                  <v-icon class="aboutEmailIcon">mdi-email</v-icon>
                  {{ employee.email }}</a
                >
              </v-card-text>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <div>
      <p class="text-h2 text-center font-weight-bold aboutHeader">Our Board</p>
      <div class="teamRoot">
        <div class="teamWrapper">
          <div v-for="boardMember in boardMembers" :key="boardMember.id">
            <v-card class="my-12 teamCard">
              <v-card-title class="aboutNameBoard">{{
                boardMember.Name
              }}</v-card-title>
              <div class="aboutTitleEmailWrapper">
                <v-card-text class="aboutTitle">
                  <p>{{ boardMember.Title }}</p>
                  <p class="italics">{{ boardMember.Company_Organization }}</p>
                </v-card-text>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";


export default {
  name: "AboutUs",
  data: () => ({
    boardMembers: [],
    employees: []
  }),
    async mounted() {
    try {
      axios.all([
        axios.get("https://strapi.nyk.mindex.cloud/employees"),
        axios.get("https://strapi.nyk.mindex.cloud/board-members")
      ])
      .then(
        axios.spread((employees, boardMembers) => {
          this.employees = employees.data.sort((a, b) => (a.Order > b.Order ? 1 : -1));
          this.boardMembers = boardMembers.data.sort((a, b) => (a.Order > b.Order ? 1 : -1));
        })
      )
    } catch (error) {
      this.error = error;
    }
  },
  methods: {
  },
};
</script>

<style scoped>
.cardImg {
  height: 500px;
  width: 50%;
}

.cardImgTwo {
  max-width: 400px;
  width: 100%;
  margin: auto;
}
.cardWrapper {
  margin: 40px 0 40px 0;
}
.classesWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.teamRoot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.teamWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.teamCard {
  margin: 10px;
  width: 300px;
  height: 250px;
}
.aboutTitle {
  font-weight: bold;
  text-align: center;
  padding-bottom: 0;
  margin-top: 10px;
  font-size: 1rem;
  color: black !important;
}
.aboutName {
  justify-content: center;
  color: white;
  background-color: black;
}
.aboutNameBoard {
  justify-content: center;
  color: white;
  background-color: black;
}
.aboutEmail {
  text-align: center;
  margin-top: 10px;
}

.italics {
  font-style: italic;
  font-weight: normal;
}

.aboutEmail a {
  text-decoration: none;
  color: black;
}
.aboutEmailIcon {
  margin: 0 10px 0 10px;
}
.aboutTitleEmailWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.aboutHeader {
  font-size: 2.5rem !important;
}
.lakeHouseHeader {
  margin: 15px;
}
.sponsorLogo {
  width: 250px;
}
.sponsorLogoWrapper {
  display: flex;
}

@media only screen and (max-width: 960px) {
  .cardImg {
    width: 100%;
    margin-bottom: 20px !important;
  }
}
@media only screen and (max-width: 600px) {
  .cardImg {
    max-height: 240px;
  }
  .sponsorLogoWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .cardWrapper {
    margin: 0 0 40px 0;
  }
}
</style>
